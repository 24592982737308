import styled from 'styled-components';
import {colors} from '../../styles/vars';

export const Main = styled.div`
    text-align: center;
    color: #fff;
    font-weight: 400;
    padding-top: 40px;
    min-height: 100%;

    a {
        color: ${colors.blue.normal};

        &:hover {
            color: ${colors.blue.light};
        }
    }
`;

export const Title = styled.div`
    font-size: 30px;
    font-weight: 700;
`;
