import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-browser-router'
import createHistory from 'history/createBrowserHistory'

// import "./ui/styles.css";
import App from "./router/app";

const history = createHistory()

if(window.location.hash && window.location.hash.indexOf('#access_token') === -1){
  let path = window.location.hash.slice(1);

  if (path) {
    window.location.hash = '';
    window.history.replaceState({}, '', `/${path}`);
  }
}

function Main() {

  return (
      <BrowserRouter history={history}>
        <div>
          <App />
        </div>
      </BrowserRouter>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Main />, rootElement);
