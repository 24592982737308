import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';
import { createLjlPlayerInfo, LjlPlayerInfos } from '../../paramTypes';

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "ljlJsonTeamProcessor",
        inputs: [{
            id: shortID(),
            name: "jsonObject",
            type: "any"
        }, {
            id: shortID(),
            name: "teamTricode",
            type: "string"
        }],
        outputs: [{
            id: shortID(),
            name: "output",
            type: "LjlPlayerInfos"
        }],
    }),
    process: (input: any[], nodeId: string, processedByLiveScreen : boolean) => {
        if (!input || input.length < 2) return [null];
        const jsonObject: any = input[0];
        const tricode: string = input[1];

        if (!jsonObject.teams) {
            return [null];
        }
        if (!jsonObject.teams[tricode]) {
            return [null];
        }

        const roster = jsonObject.teams[tricode].roster;
        let Top, Jungle, Mid, Adc, Support;
        Object.values(roster).forEach((player: any) => {
            switch (player.position) {
                case 'top':
                    Top = createLjlPlayerInfo(player.name, player.images.matchup, player.images.mvp);
                    break;
                case 'jungle':
                    Jungle = createLjlPlayerInfo(player.name, player.images.matchup, player.images.mvp);
                    break;
                case 'mid':
                    Mid = createLjlPlayerInfo(player.name, player.images.matchup, player.images.mvp);
                    break;
                case 'adc':
                    Adc = createLjlPlayerInfo(player.name, player.images.matchup, player.images.mvp);
                    break;
                case 'support':
                    Support = createLjlPlayerInfo(player.name, player.images.matchup, player.images.mvp);
                    break;
                default:
                    break;
            }
        });

        return [new LjlPlayerInfos({
            Top, Jungle, Mid, Adc, Support,
        })];
    },
}
