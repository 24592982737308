import React from "react";
import { ScreenGroup } from "../nodeSystem/ScreenGroup";
import { DataItem } from "../nodeSystem/defs";
import shortID from "../utils/shortID";
import { paramTypes } from "../nodeSystem/paramTypes";

export const GraphInputs = (props) => {
  const screenGroup : ScreenGroup = props.screenGroup;
  const addRefNodeFunc = props.addRefNodeFunc;
  const deleteRefNodeFunc = props.deleteRefNodeFunc;
  const [componentID, setComponentID] = React.useState(shortID());

  const [newID, setNewID] = React.useState('');
  const [newType, setNewType] = React.useState(paramTypes[0] || '');

  const deleteInput = async (id : string) => {
    await deleteRefNodeFunc.run(id);
    refresh();
  };

  const refresh = () => {
    setComponentID(shortID());
  }
  
  const inputs = (screenGroup && screenGroup.data && screenGroup.data.inputs) || [];
  const [keysValues, dispatchKeysValues] = React.useReducer(keysValuesReducer, keysValuesReducer(null, screenGroup));

  const addInput = (id : string, type : string) => {
    screenGroup.addDataSourceInput(id, id, type);
    setNewID('');
    setNewType(paramTypes[0] || '');
    dispatchKeysValues(screenGroup);
    // refresh();
  };

  return (
    <div id={componentID} key={componentID}>
      <div style={{border: '5px solid green'}}>
      {
        inputs.map((input : DataItem) => (
          <div key={input.id}>
            <table><tbody><tr><td>ID:</td><td>{input.id}</td></tr><tr><td>key:</td><td><input type='text' value={keysValues[input.id]} onChange={event => updateKey(input.id, event.target.value, dispatchKeysValues, screenGroup)}/></td></tr></tbody></table>
            ({input.type})
            <button onClick={() => addRefNodeFunc.run(input.id)}>Ref</button>
            <p><button onClick={() => deleteInput(input.id)}>Delete</button></p>
          </div>
        ))
      }
      </div>
      <div style={{border: '5px solid green'}}>
      <button onClick={() => addInput(newID, newType)}>Add Input</button>
      {
        renderCreateBox(setNewID, setNewType, newID, newType)
      }
      </div>
    </div>
  );
};

function keysValuesReducer(state, screenGroup: ScreenGroup) {
  const ret = {};
  (screenGroup && screenGroup.data && screenGroup.data.inputs || []).forEach((input : DataItem) => {
    ret[input.id] = input.name;
  });
  return ret;
}

const renderCreateBox = (setNewID : Function, setNewType : Function, newID : string, newType : string) => (
  <div>
      <table><tbody>
        <tr>
          <td>ID:</td>
          <td><input type='text' value={newID} onChange={event => setNewID(event.target.value)}></input></td>
        </tr>
        <tr>
          <td>Type:</td>
          <td>
            <select name="newType" value={newType} id="newType" onChange={event => setNewType(event.target.value)}>
              {
                paramTypes.map((type, i) => (
                  <option key={`newParamType${i}`} value={type}>{type}</option>
                ))
              }
            </select>
        </td>
        </tr>
      </tbody></table>
  </div>
);

const updateKey = async (inputId : string, key : string, dispatch : Function, group : ScreenGroup) => {
  group.updateDataSourceInputKey(inputId, key);
  dispatch(group);
};
