import { paramMappings } from "./paramTypes"
import shortID from "../utils/shortID"

export class ProcessingNode {
    id: string
    type: string // this will represent the type of node, should be put in folder src/nodeTypes/${type}/index.ts
    inputs: DataItem[]
    outputs: DataItem[]
    posX: number = 0// x position to display this node on the Node Group View
    posY: number = 0// y position to display this node on the Node Group View
    isConstant: boolean
    constructor(data: Partial<ProcessingNode>) {
        this.id = data.id;
        this.type = data.type;
        this.inputs = [];
        if (data.inputs) {
            data.inputs.forEach(input => {
                this.inputs.push(new DataItem(input));
            });
        }
        this.outputs = [];
        if (data.outputs) {
            data.outputs.forEach(output => {
                this.outputs.push(new DataItem(output));
            });
        }
        this.isConstant = false;
        this.posX = data.posX || 0;
        this.posY = data.posY || 0;
    }
}

export class ConstantNode extends ProcessingNode {
    constantType: string
    value: any
    constructor(data: Partial<ConstantNode>) {
        super(data);
        this.constantType = data.constantType;
        this.value = data.value;
        this.isConstant = true;
    }
}

export class DataItem {
    id: string
    name: string
    type: string // primitive or struct type
    constructor(data: Partial<DataItem>) {
        Object.assign(this, data);
    }
}

export class Screen {
    id: string
    name: string
    type: string
    inputs: DataItem[]
    constructor(data: Partial<Screen>) {
        this.id = data.id;
        this.name = data.name;
        this.type = data.type;
        this.inputs = [];
        if (data.inputs) {
            data.inputs.forEach(input => {
                this.inputs.push(new DataItem(input));
            });
        }
    }
}

export class ScreenGroupData {
    id: string
    name: string
    inputs: DataItem[] // 'name' value is the key inside firebase or coming from data origin
    screens: Screen[]
    nodes: ProcessingNode[]
    connections: any
    constructor(data: Partial<ScreenGroupData>) {
        if(data.id){
            this.id = data.id;
        }else{
            this.id = shortID();
        }
        this.name = data.name;
        this.inputs = [];
        if (data.inputs) {
            data.inputs.forEach(input => {
                this.inputs.push(new DataItem(input));
            });
        }
        this.screens = [];
        if (data.screens) {
            data.screens.forEach(screen => {
                this.screens.push(new Screen(screen));
            });
        }
        this.nodes = [];
        if (data.nodes) {
            data.nodes.forEach(node => {
                if(node.isConstant){
                    const cNode = new ConstantNode(node);
                    if(paramMappings[cNode.constantType]){
                        cNode.value = paramMappings[cNode.constantType].create(cNode.value);
                    }
                    this.nodes.push(cNode);
                }else{
                    this.nodes.push(new ProcessingNode(node));
                }
            })
        }
        this.connections = data.connections || {};
    }
}
