import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "objectPath",
        inputs: [{
            id: shortID(),
            name: "inputJson",
            type: "any"
        }, {
            id: shortID(),
            name: "path",
            type: "string"
        }],
        outputs: [{
            id: shortID(),
            name: "output",
            type: "any"
        }],
    }),
    process: (input: any[], nodeId : string, processedByLiveScreen : boolean) => {
        if (!input || input.length < 2) return [null];
        const data: any = input[0];
        const parseCode = input[1];
        if (!data || !parseCode) {
            return [null];
        }

        const path = parseCode.split('.');

        let currentValue = data;
        for(let i=0;i < path.length;i++){
            currentValue = currentValue[path[i]];
        }
        
        return [currentValue];
    },
}
