
export const isInRadius = (pos1, pos2, radius) => {
    if(!pos1 || !pos2 || !radius) return false;
    const xDelta = Math.abs(pos1.x - pos2.x);
    const zDelta = Math.abs(pos1.z - pos2.z);
    const distance = Math.sqrt(Math.pow(xDelta, 2) + Math.pow(zDelta, 2));
    return distance < radius;
}

export const getJunglerProximity = (junglerPosition, otherPositions) =>{
    if(!junglerPosition || !otherPositions) return [];
    let result = new Array(otherPositions.length).fill(0);
    let counter = new Array(otherPositions.length).fill(0);  //number of positions counted
    for(let i = 180; i < 900; i++){ //the proximity value is always calculated based on the 3-15min time period
        // eslint-disable-next-line no-loop-func
        otherPositions.forEach((positions, index)=>{
            if(junglerPosition[i] && positions[i]){
                counter[index] = counter[index]+1;
                if(isInRadius(junglerPosition[i], positions[i], 2000)){
                    result[index] = result[index] +1;
                }
            }
        })
    }
    result = result.map((value, index) => Math.round((value/counter[index])*100));
    return result;
}

const defaultMapSize = {x: 15000, z: 15000}; // map size in unit from RiotLiveAPI

// define if a point is placed on the blue side (bottom-left) or red side (top-right)
export const defineMapSide = (position, mapSize) => {
    const size = {...defaultMapSize};
    if(mapSize && mapSize.x && mapSize.z){
        size.x = mapSize.x;
        size.z = mapSize.z;
    }
    if(
        !position ||
        position.x === undefined ||
        position.z === undefined ||
        (Math.min(0 , position.x, position.z) !== 0) ||
        (size.x < position.x) ||
        (size.z < position.z)
    ){
        return undefined;
    }
    const zRatio = (size.z - position.z) / size.z; //0.3
    if((zRatio * size.x) < position.x){
        const xRatio = position.x / size.x;
        if((xRatio * size.z) > (size.z - position.z)){
            return 'red';
        }
    }
    return 'blue';
}

export const creepsType = [
    'BLUE_KRUG',
    'BLUE_REDBUFF',
    'BLUE_RAPTOR',
    'BLUE_WOLF',
    'BLUE_BLUEBUFF',
    'BLUE_GROMP',
    'TOP_SCUTTLECRAB',
    'RED_KRUG',
    'RED_REDBUFF',
    'RED_RAPTOR',
    'RED_WOLF',
    'RED_BLUEBUFF',
    'RED_GROMP',
    'BOT_SCUTTLECRAB',
];

export const APICreepMonsters = [
    'blueCamp',
    'redCamp',
    'gromp',
    'wolf',
    'scuttleCrab',
    'raptor',
    'krug',
]

export const defineCreep = (monsterType : string, position : any, mapSize : any = null) => {
    if(
        !APICreepMonsters ||
        !APICreepMonsters.includes(monsterType)){
        return;
    }
    const side = defineMapSide(position, mapSize);
    if(!side){return;}

    if(monsterType === 'scuttleCrab'){
        const size = {...defaultMapSize};
        if(mapSize && mapSize.x && mapSize.z){
            size.x = mapSize.x;
            size.z = mapSize.z;
        }
        if(position.z < (size.z/2)){
            return 'BOT_SCUTTLECRAB';
        }
        return 'TOP_SCUTTLECRAB';
    }

    switch(monsterType){
        case 'blueCamp':
            return `${side.toUpperCase()}_BLUEBUFF`;
        case 'redCamp':
            return `${side.toUpperCase()}_REDBUFF`;
        case 'gromp':
        case 'wolf':
        case 'krug':
        case 'raptor':
            return `${side.toUpperCase()}_${monsterType.toUpperCase()}`;
        default:
            return;
    }
}