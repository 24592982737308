import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';
import { LjlDataStore, LjlPlayerInfos, TimerData } from '../../paramTypes';
import { processSendData, buildMatchGamesIfNeeded } from './riotApiUtil';


const dragonDisplayNameMap = {
    air: 'Cloud',
    earth: 'Mountain',
    fire: 'Infernal',
    water: 'Ocean',
    elder: 'Infernal', // to prevent crashes.
}

const initialTurretStatus = {
    team100: {
        top: 0,
        mid: 0,
        bot: 0,
    },
    team200: {
        top: 0,
        mid: 0,
        bot: 0,
    },
}

// TODO: theme
const assets = {
    // Cloud
    drakeOverlayCloud:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Cloud.png',
    drakeOverlayCloudRift:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Cloud_Rift.png',
    drakeOverlayCloudElder:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Cloud_Elder.png',
    
    // Infernal
    drakeOverlayInfernal:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Infernal.png',
    drakeOverlayInfernalRift:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Infernal_Rift.png',
    drakeOverlayInfernalElder:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Infernal_Elder.png',
    
    // Mountain
    drakeOverlayMountain:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Mountain.png',   
    drakeOverlayMountainRift:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Mountain_Rift.png',   
    drakeOverlayMountainElder:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Mountain_Elder.png',
    
    // Ocean
    drakeOverlayOcean:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Ocean.png',
    drakeOverlayOceanRift:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Ocean_Rift.png',
    drakeOverlayOceanElder:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Ocean_Elder.png',
    
    // Tokens
    drakeOverlayTokensContainer: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Tokens_Container.png',
    drakeTokens: {
        token: {
            Cloud: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Token_Cloud.png', 
            Infernal: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Token_Infernal.png',
            Mountain: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Token_Mountain.png',
            Ocean: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Token_Ocean.png'
        },
        soul: {
            Cloud: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Soul_Cloud.png', 
            Infernal: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Soul_Infernal.png',
            Mountain: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Soul_Mountain.png',
            Ocean: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Soul_Ocean.png'
        },
    },
    // Buff
    // elderBuffBlue: //=> Not compatible with baron buff sponsors
    //     'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/elder_buff_blue.png',
    elderBuffBlue:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/elder_buff_blue2.png',
    baronBuffBlue:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/baron_buff_blue.png',
    // elderBuffRed:  //=> Not compatible with baron buff sponsors
    //     'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/elder_buff_red.png',
    elderBuffRed:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/elder_buff_red2.png',
    baronBuffRed:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/baron_buff_red.png',
    baronBuff:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/baron_buff_timer.png',

    // Baron & Herald
    baronOverlay:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/baron.png',
    heraldOverlay:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/rift_herald.png',
    
    drakeOverlayElder:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Elder.png',
    drakeOverlayElderNormal:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Elder_Normal.png',

    baronLive:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Baron_Overlay_live.png',
    drakeOverlayElderLive:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Elder_live.png',
    
    inhibitorBlue:'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Inhibitor-Timer_Blue.png',
    inhibitorRed: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Inhibitor-Timer_Red.png',
    
    heraldOverlayLive:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/rift_herald_live.png',
    tokenOverlayBox:
        'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Overlay_Tokens_Container.png'
};

let currentPlatformIdGameId = '';
const dataroot = 'dataApiListener';

const processedEvents = {};

const stateBasedEventsIds = {};

const nextSpawnGameTimes = {
    herald: 480,
    baron: 1200,
}
const constantRespawnTimes = {
    herald: 360,
    baron: 360,
}

const timerLengthSeconds : {[timerType : string] : number} = {
    inhibitor: 300,
    dragon: 120,
    herald: 120,
    baron: 120,
    buffElder: 150,
    buffBaron: 180,
};

let bans : string[][] = [[],[]];
let visibleTimers : {[timerName : string] : boolean} = {};
let timerEndTimeGameSeconds : {[timerName : string] : number} = {};
let realtimeListenerIsUpToDate = false;

let timerGameTimeTriggers : {[timerName : string] : boolean} = {
    herald: false,
    heraldLive: false,
    baron: false,
    baronLive: false,
    dragon: false,
    dragonLive: false,
};

let nextDragonSubType = ''; // 'normal' (default), 'rift', 'elder'
let nextDragonSpawnGameTime = 0;
let nextDragonType = ''; // 'Infernal' (default), 'Mountain', 'Ocean' or 'Cloud' 
let elderDragonCounter = 0;

let dragonKills200 = 0;
let dragonKills100 = 0;

let dragonTokens : {[team : string] : {tokens: string[], soul: string}} = {
    teamLeft: {
        tokens: [],
        soul: '',
    },
    teamRight: {
        tokens: [],
        soul: '',
    },
};

let isPaused = false;
let pauseStartTime = 0;
let pauseEndTime = 0;

let currentGameTime = 0;
let gameTimeAtStart = 0;
let gameStartTime = 0;
let gameActive = false;

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "lolGameScreen",
        inputs: [{
            id: shortID(),
            name: "events",
            type: "any[]"
        },{
            id: shortID(),
            name: "schedule",
            type: "any"
        },{
            id: shortID(),
            name: "teamsDefinitions",
            type: "any"
        },{
            id: shortID(),
            name: "round",
            type: "number"
        },{
            id: shortID(),
            name: "match",
            type: "number"
        },{
            id: shortID(),
            name: "game",
            type: "number"
        },{
            id: shortID(),
            name: "platformID",
            type: "string"
        },{
            id: shortID(),
            name: "gameID",
            type: "string"
        }],
        // season and split should prased via separate nodes since they aren't technically a game screen property.
        // standings Left and standingsRight should come from standings node output
        outputs: [{
            id: shortID(),
            name: 'bestOf',
            type: 'number',
        },{
            id: shortID(),
            name: 'gameStarted',
            type: 'boolean',
        },{
            id: shortID(),
            name: 'gameTimeSeconds',
            type: 'number',
        },{
            id: shortID(),
            name: 'gamePaused',
            type: 'boolean',
        },{
            id: shortID(),
            name: 'Drake',
            type: 'TimerData',
        }, {
            id: shortID(),
            name: 'HeraldSpawn',
            type: 'TimerData',
        }, {
            id: shortID(),
            name: 'BaronSpawn',
            type: 'TimerData',
        }, {
            id: shortID(),
            name: 'ElderBuff100',
            type: 'TimerData',
        }, {
            id: shortID(),
            name: 'ElderBuff200',
            type: 'TimerData',
        }, {
            id: shortID(),
            name: 'BaronBuff100',
            type: 'TimerData',
        }, {
            id: shortID(),
            name: 'BaronBuff200',
            type: 'TimerData',
        },{
            id: shortID(),
            name: 'inhibitor100Visible',
            type: 'boolean',
        },{
            id: shortID(),
            name: 'inhibitor200Visible',
            type: 'boolean',
        },{
            id: shortID(),
            name: 'inhibitor100TopEndTimeSeconds',
            type: 'number',
        },{
            id: shortID(),
            name: 'inhibitor100MidEndTimeSeconds',
            type: 'number',
        },{
            id: shortID(),
            name: 'inhibitor100BottomEndTimeSeconds',
            type: 'number',
        },{
            id: shortID(),
            name: 'inhibitor200TopEndTimeSeconds',
            type: 'number',
        },{
            id: shortID(),
            name: 'inhibitor200MidEndTimeSeconds',
            type: 'number',
        },{
            id: shortID(),
            name: 'inhibitor200BottomEndTimeSeconds',
            type: 'number',
        },{
            id: shortID(),
            name: 'Bans',
            type: 'string[]',
        },{
            id: shortID(),
            name: 'realtimeListenerIsUpToDate',
            type: 'boolean',
        },{
            id: shortID(),
            name: 'nextDragonType',
            type: 'string',
        },{
            id: shortID(),
            name: 'nextDragonSubType',
            type: 'string',
        },{
            id: shortID(),
            name: 'dragonTokens',
            type: 'any',
        }],
    }),
    process: (input: any[], nodeId: string, processedByLiveScreen : boolean) => {
        if (!input || input.length < 1) return [null];
        try {
            const schedule = input[1];
            if(!dataStore.schedule && !schedule){
                return [[]];
            }

            if(currentPlatformIdGameId !== `${input[6]}-${input[7]}`){
                currentPlatformIdGameId = `${input[6]}-${input[7]}`;
                resetData();
            }

            if(schedule){
                dataStore.schedule = schedule;
            }
            const teamsDefs = input[2];
            dataStore.schedule.round = input[3] || 0;
            dataStore.schedule.match = input[4] || 0;
            dataStore.schedule.game = input[5] || 0;

            buildMatchGamesIfNeeded(dataStore.schedule.round, dataStore.schedule.match, dataStore, teamsDefs);

            const events = input[0];
            const sendDataArr = processEvents(events, {
                'bannedChampions': processBans,
            });

            if(sendDataArr.length > 0 && sendDataArr[0].gameTime && sendDataArr[0].date){
                currentGameTime = Math.floor(sendDataArr[0].gameTime/1000);
                gameTimeAtStart = currentGameTime;
                gameStartTime = Date.parse(sendDataArr[0].date);
                gameActive = true;
            }

            // callbackIds['pause_started'] = window.MainData.dataApiListener.component.registerCallback('pause_started', this.pauseStartedFunction, processedSequenceSet);
            // callbackIds['pause_ended'] = window.MainData.dataApiListener.component.registerCallback('pause_ended', this.pauseEndedFunction, processedSequenceSet);
            // callbackIds['building_destroyed'] = window.MainData.dataApiListener.component.registerCallback('building_destroyed', this.buildingDestroyedFunction, processedSequenceSet);
            // callbackIds['queued_dragon_info'] = window.MainData.dataApiListener.component.registerCallback('queued_dragon_info', this.queuedDragonInfoFunction, processedSequenceSet);
            // callbackIds['epic_monster_kill'] = window.MainData.dataApiListener.component.registerCallback('epic_monster_kill', this.epicMonsterKillFunction, processedSequenceSet);
            // callbackIds['stats_update'] = window.MainData.dataApiListener.component.registerCallback('stats_update', this.checkIfUpToDate);

            // console.log('sendDataArr', JSON.stringify(sendDataArr));
            let sendDataResults = [];
            // for(let i=0;i < sendDataArr.length;i++){
            //     sendDataResults.push(processEvent(sendDataArr[i], null));
            //     console.log('last result', sendDataResults[sendDataResults.length-1]);
            // }
            sendDataResults = sendDataArr.filter(data => data);
            console.log('sendData', JSON.stringify(sendDataResults));

            for(let i=0;i < sendDataResults.length;i++){
                processSendData(sendDataResults[i], dataStore, processedEvents, stateBasedEventsIds, {
                    'building_destroyed': buildingDestroyedFunction,
                    'queued_dragon_info': queuedDragonInfoFunction,
                    'epic_monster_kill': epicMonsterKillFunction,
                    'stats_update': checkIfUpToDate,
                    'pause_started': pauseStartedFunction,
                    'pause_ended': pauseEndedFunction,
                });
            }

            console.log('dataStore', JSON.stringify(dataStore));

            console.log('bans', JSON.stringify(bans));

            const match = dataStore.schedule.rounds[dataStore.schedule.round].matches[dataStore.schedule.match];

            return [
                (match && match.bestOf) || 1,
                gameActive,
                getCurrentGameTime(),
                isPaused,
                new TimerData({
                    isLiveVisible: timerGameTimeTriggers['dragonLive'],
                    isTimerVisible: timerGameTimeTriggers['dragon'],
                    endingGameTimeSeconds: nextDragonSpawnGameTime, // the game time by which the spawn countdown should end
                    ImgUrl: getDragonImage(),
                }), 
                new TimerData({
                    isLiveVisible: timerGameTimeTriggers['heraldLive'],
                    isTimerVisible: timerGameTimeTriggers['herald'],
                    endingGameTimeSeconds: nextSpawnGameTimes.herald,
                    ImgUrl: assets.heraldOverlay,
                }), 
                new TimerData({
                    isLiveVisible: timerGameTimeTriggers['baronLive'],
                    isTimerVisible: timerGameTimeTriggers['baron'],
                    endingGameTimeSeconds: nextSpawnGameTimes.baron,
                    ImgUrl: assets.baronOverlay,
                }),
                new TimerData({
                    isTimerVisible: visibleTimers['buffElder100'],
                    endingGameTimeSeconds: timerEndTimeGameSeconds['buffElder100'],
                    ImgUrl: assets.elderBuffBlue,
                }),
                new TimerData({
                    isTimerVisible: visibleTimers['buffElder200'],
                    endingGameTimeSeconds: timerEndTimeGameSeconds['buffElder200'],
                    ImgUrl: assets.elderBuffRed,
                }),
                new TimerData({
                    isTimerVisible: visibleTimers['buffBaron100'],
                    endingGameTimeSeconds: timerEndTimeGameSeconds['buffBaron100'],
                    ImgUrl: assets.baronBuffBlue,
                }), 
                new TimerData({
                    isTimerVisible: visibleTimers['buffBaron200'],
                    endingGameTimeSeconds: timerEndTimeGameSeconds['buffBaron200'],
                    ImgUrl: assets.baronBuffRed,
                }),
                visibleTimers['inhibitor100'],
                visibleTimers['inhibitor200'],
                timerEndTimeGameSeconds['inhibitor100Top'],
                timerEndTimeGameSeconds['inhibitor100Mid'],
                timerEndTimeGameSeconds['inhibitor100Bottom'],
                timerEndTimeGameSeconds['inhibitor200Top'],
                timerEndTimeGameSeconds['inhibitor200Mid'],
                timerEndTimeGameSeconds['inhibitor200Bottom'],
                bans,
                realtimeListenerIsUpToDate,
                nextDragonType,
                nextDragonSubType,
                dragonTokens,
            ];
        } catch (error) {
            console.log('error', error);
            return [null];
        }
    },
}

const getDragonImage = () => {
    if(nextDragonSubType === 'elder'){
        return assets.drakeOverlayElder;
    }
    switch(nextDragonType){
        case 'Cloud':
            return nextDragonSubType === 'rift' ? assets.drakeOverlayCloudRift : assets.drakeOverlayCloud;
        case 'Mountain':
            return nextDragonSubType === 'rift' ? assets.drakeOverlayMountainRift : assets.drakeOverlayMountain;
        case 'Infernal':
            return nextDragonSubType === 'rift' ? assets.drakeOverlayInfernalRift : assets.drakeOverlayInfernal;
        case 'Ocean':
            return nextDragonSubType === 'rift' ? assets.drakeOverlayOceanRift : assets.drakeOverlayOcean;
        default:
            return '';
    }
};

const processBans = (bannedChampions : any[]) => {
    bans[0] = [];
    bans[1] = [];
    bannedChampions.forEach(ban => {
        if (ban.teamID === 100) {
            bans[0].push(ban.championID.toString());
        }
        if (ban.teamID === 200) {
            bans[1].push(ban.championID.toString());
        }
    });
};

let dataStore : LjlDataStore = {};

export const stateBasedEvents = ['champ_select']; // we defer these to only process the last one in a series.

const resetData = () => {
    dataStore = {};
    bans = [[],[]];
    nextSpawnGameTimes.herald = 480;
    nextSpawnGameTimes.baron = 1200;
    dragonTokens = {
        teamLeft: {
            tokens: [],
            soul: '',
        },
        teamRight: {
            tokens: [],
            soul: '',
        },
    };
 
    visibleTimers  = {};
    timerEndTimeGameSeconds = {};
    realtimeListenerIsUpToDate = false;

    timerGameTimeTriggers  = {
        herald: false,
        heraldLive: false,
        baron: false,
        baronLive: false,
        dragon: false,
        dragonLive: false,
    };

    nextDragonSubType = '';
    nextDragonSpawnGameTime = 0;
    nextDragonType = '';
    elderDragonCounter = 0;

    dragonKills200 = 0;
    dragonKills100 = 0;

    isPaused = false;
    pauseStartTime = 0;
    pauseEndTime = 0;

    
    currentGameTime = 0;
    gameTimeAtStart = 0;
    gameStartTime = 0;
    gameActive = false;
};

const processEvents = (events, fieldCallbacks) : any[] => {
    const sendDataArr : any[] = [];
    if (events) {
        let deferred = {};
        events.sort((a, b) => a.sequenceIndex - b.sequenceIndex);
        for (let i = 0; i < events.length; i++){
            const event = events[i];
            if (event) {
                const eventTimestamp = event.rfc460Timestamp && Date.parse(event.rfc460Timestamp);

                if (stateBasedEvents.includes(event.rfc461Schema)) {
                    deferred[event.rfc461Schema] = () => sendDataArr.push(processEvent(event, fieldCallbacks));
                } else {
                    if (event.sequenceIndex === 0) {
                        Object.keys(deferred).forEach(key => deferred[key]());
                        deferred = {};
                    }
                    sendDataArr.push(processEvent(event, fieldCallbacks));
                }
            }
        }
        Object.keys(deferred).forEach(key => deferred[key]());
    }

    return sendDataArr.filter(data => data);
};


const processEvent = (event, fieldCallbacks) : any => {
    const sendData : any = {};
    const fields = [];
    const monsterTypes = ['dragon', 'riftHerald', 'baron', 'scuttleCrab'];
    const teamOne = {};
    const teamTwo = {};
    switch (event.rfc461Schema) {
        // case 'turret_plate_destroyed':
        //     fields.push(Object.keys(event));
        //     break;
        case 'epic_monster_kill':
            // if (!monsterTypes.includes(event.monsterType)) {
            //     return;
            // }
            console.log('MONSTER KILL', event);
            fields.push('monsterType', 'dragonType', 'gameTime', 'killerTeamID', 'killer', 'position', 'assistants');
            break;
        case 'epic_monster_spawn':
            if (!monsterTypes.includes(event.monsterType)) {
                return;
            }
            fields.push('monsterType', 'gameTime', 'dragonType');
            break;
        case 'item_destroyed':
            if (![3513, 3514].includes(event.itemID)) { // herald item
                return;
            }
            fields.push('participantID', 'gameTime', 'itemID', 'rfc461Schema');
            break;
        case 'item_purchased':
        case 'item_sold':
        case 'item_undo':
            fields.push('participantID', 'gameTime', 'itemID', 'rfc461Schema');
            break;
        case 'queued_dragon_info':
            fields.push('dateUnix', 'nextDragonSpawnTime', 'nextDragonName');
            break;
        case 'champ_select': {
            fields.push('pickTurn', 'gameState', 'bannedChampions');
            const convertPlayer = (player, index) => ({
                arrayIndex: index,
                // participantID: player.participantID,
                championID: player.championID, // this is non-zero after player has chosen a champion
                championName: null, // this will become set when champion is finalized, and we will set championID back to 0
                pickTurn: player.pickTurn,
                // Pick Mode : 0=> No picking, 1 => picking Time, 2=> Champion selected and confirmed
                pickMode: player.pickMode, // if he was the last person that picked, this = 1 I think
                summonerName: player.summonerName, // SG Reiya
                summonerInternalName: player.summonerInternalName, // sgreiya
                accountID: `${player.accountID}`,
                selectedSkinIndex: player.selectedSkinIndex,
                profileIconID: player.profileIconID,
                summonerID: player.summonerID,
                spell1: player.spell1,
                spell2: player.spell2,
            });
            event.teamOne && event.teamOne.forEach((player, index) => {
                teamOne[player.accountID] = convertPlayer(player, index);
            });
            event.teamTwo && event.teamTwo.forEach((player, index) => {
                teamTwo[player.accountID] = convertPlayer(player, index);
            });
            
            sendData.teamOne = teamOne;
            sendData.teamTwo = teamTwo;
            sendData.bannedChampions = event.bannedChampions;
        }
            break;
        case 'game_info': {
            const convertParticipant = (participant, index) => ({
                arrayIndex: index,
                participantID: participant.participantID,
                championName: participant.championName,
                championID: 0,
                teamID: participant.teamID,
                summonerName: participant.summonerName,
                perks: participant.perks,
                keystoneID: participant.keystoneID,
            });
            let teamOneIndex = 0;
            let teamTwoIndex = 0;
            event.participants.forEach(participant => {
                if (participant.teamID === 100) {
                    teamOne[participant.accountID] = convertParticipant(participant, teamOneIndex++);
                } else {
                    teamTwo[participant.accountID] = convertParticipant(participant, teamTwoIndex++);
                }
            });
            sendData.teamOne = teamOne;
            sendData.teamTwo = teamTwo;
        }
        break;
        case 'building_destroyed':
            if (event.buildingType !== 'inhibitor') return;
            fields.push('turretTier', 'gameTime', 'teamID', 'lastHitter', 'position', 'lane', 'buildingType', 'assistants');
            break;
        case 'turret_plate_destroyed':
            // console.log("turret_plate_destroyed", event);
            fields.push('teamID', 'gameTime', 'lane', 'position');
            break;
        case 'pause_started':
            fields.push('gameTime');
            break;
        case 'pause_ended':
            fields.push('gameTime');
            break;
        case 'champion_kill':
            fields.push('killerTeamID', 'killer', 'victim', 'killStreakLength');
            break;
        case 'champion_kill_special':
            fields.push('killType', 'killer', 'killStreakLength');
            break;
        case 'champion_level_up':
            fields.push('gameTime');
            break;
        case 'skill_level_up':
            fields.push('gameTime');
            break;
        case 'stats_update':
            fields.push('gameTime', 'participants', 'teams');
            break;
        default:
            // console.log('ZZZ ', event.rfc461Schema, event);
            return;
    }
    fields.forEach(field => { 
        sendData[field] = event[field];
    });

    sendData.date = event.rfc460Timestamp;
    sendData.action = event.rfc461Schema;
    sendData.sequenceIndex = event.sequenceIndex;
    sendData.platformID = event.platformID;
    sendData.gameID = event.gameID;
    // callback && callback(sendData);

    if (fieldCallbacks) {
        Object.keys(fieldCallbacks).forEach(key => {
            if (sendData[key]) {
                fieldCallbacks[key](sendData[key]);
            }
        });
    }
    return sendData;
};


const buildingDestroyedFunction = async liveStats => {
    checkIfUpToDate(liveStats);
    // this.addToProcessedSequences(liveStats);
    // const timeDiffSec = getTimeDiffSec(liveStats);
    // const timeSec = getTimeSec(liveStats);
    // console.log('TIME DIFF SEC', timeDiffSec);
    if (liveStats.buildingType === 'inhibitor') {
        let lane = liveStats.lane;
        if (liveStats.teamID === 100 && !visibleTimers.inhibitor100) {
            setVisible('inhibitor100');
        }
        if (liveStats.teamID === 200 && !visibleTimers.inhibitor200) {
            setVisible('inhibitor200');
        }
        timerEndTimeGameSeconds[`inhibitor${liveStats.teamID}${lane.charAt(0).toUpperCase()}${lane.slice(1)}`] = 
            Math.round(liveStats.gameTime/1000) + timerLengthSeconds['inhibitor'];
            // ((liveStats && liveStats.date && (Math.round(Date.parse(liveStats.date)/1000))) || getCurrentTimestampSeconds()) + timerLengthSeconds['inhibitor'];
            

        // if (lane === 'bot') {
        //     timerEndTimeSeconds[`inhibitor${liveStats.teamID}Bottom`] = timeSec + 300;
        //     // this.timers[`inhibitor${liveStats.teamID}Bottom`] = inhibitorTime;
        //     // this.timersString[`inhibitor${liveStats.teamID}Bottom`] = this.formatTimer(inhibitorTime);
        //     // this.runTimerAndDisplay(`inhibitor${liveStats.teamID}Bottom`); // no need to pass time diff since its incorporated into inhibitorTime
        // } else {
        //     timerEndTimeSeconds[`inhibitor${liveStats.teamID}${lane.charAt(0).toUpperCase()}${lane.slice(1)}`] = timeSec + 300;
        //     // this.timers[`inhibitor${liveStats.teamID}${lane.charAt(0).toUpperCase()}${lane.slice(1)}`] = inhibitorTime;
        //     // this.timersString[`inhibitor${liveStats.teamID}${lane.charAt(0).toUpperCase()}${lane.slice(1)}`] = this.formatTimer(inhibitorTime);
        //     // this.runTimerAndDisplay(`inhibitor${liveStats.teamID}${lane.charAt(0).toUpperCase()}${lane.slice(1)}`); // no need to pass time diff since its incorporated into inhibitorTime
        // }
    }
};

const setVisible = (timerName : string) => {
    visibleTimers[timerName] = true;
};

const setHide = (timerName : string) => {
    visibleTimers[timerName] = false;
}


// const setDragonSubtypeSelected = (dragon : string) => {
//     nextDragonSubType = dragon;
// };

const queuedDragonInfoFunction = async liveStats => {
    checkIfUpToDate(liveStats);

    if (liveStats.nextDragonName === 'elder') {
        nextDragonSubType = 'elder';
        nextDragonSpawnGameTime = liveStats.nextDragonSpawnTime;
        // console.log('state', nextDragonSubType, dragonSelected);
    } else {
        // nextDragonType = dragonDisplayNameMap[liveStats.nextDragonName];
        nextDragonSpawnGameTime = liveStats.nextDragonSpawnTime;
        // this.setDragon(dragonDisplayNameMap[liveStats.nextDragonName]);
        nextDragonType = dragonDisplayNameMap[liveStats.nextDragonName];
        if (dragonKills200 + dragonKills100 < 2) {
            // setDragonSubtypeSelected('normal');
            nextDragonSubType = 'normal';
        }
        // console.log('state', this.state.nextDragonType, this.state.dragonSelected);
    }
    timerGameTimeTriggers['dragon'] = false;
    timerGameTimeTriggers['dragonLive'] = false;
};

const epicMonsterKillFunction = async liveStats => {
    checkIfUpToDate(liveStats);
    const timeDiffSec = getTimeDiffSec(liveStats);
    // console.log('TIME DIFF SEC', timeDiffSec);
    switch (liveStats.monsterType) {
        case 'dragon':
            setHide('dragonSelectedLive');
            if (liveStats.dragonType === 'elder') {
                elderDragonCounter++;
                const timerName = `buffElder${liveStats.killerTeamID}`;
                if (getCurrentTimestampSeconds() < (getTimeSec(liveStats) + timerLengthSeconds['buffElder'])) {
                    runTimerAndDisplay(timerName, 'buffElder', liveStats);
                }
            } else {
                if (liveStats.killerTeamID === 100) {
                    dragonKills100++;
                    const dragonKills = dragonKills100;
                    if (liveStats.dragonType && dragonTokens) { 
                        dragonTokens.teamLeft.tokens[dragonKills] = dragonDisplayNameMap[liveStats.dragonType];
                        if (dragonKills === 4) {
                            dragonTokens.teamLeft.soul = dragonDisplayNameMap[liveStats.dragonType];
                        }
                    }
                    if (dragonKills200 + dragonKills === 2) {
                        console.log('Next dragon is Rift');
                        nextDragonSubType = 'rift';
                    }
                } else {
                    dragonKills200++;
                    const dragonKills = dragonKills200;
                    if (liveStats.dragonType && dragonTokens) { 
                        dragonTokens.teamRight.tokens[dragonKills] = dragonDisplayNameMap[liveStats.dragonType];
                        if (dragonKills === 4) {
                            dragonTokens.teamRight.soul = dragonDisplayNameMap[liveStats.dragonType];
                        }
                    }
                    if (dragonKills100 + dragonKills === 2) {
                        console.log('Next dragon is Rift');
                        nextDragonSubType = 'rift';
                    }
                }
            }
            break;
        case 'baron': {
            const timerName = `buffBaron${liveStats.killerTeamID}`;
            // setHide('baronLive');
            console.log('baron died');
            // this.props.onUpdate('showPopupVideo', true); //Show Sponsor Baron Video // MOVE this to VIEW
            // TODO: GOLD DIFFERENCE
            //get the goldDifference Offset as soon as the baron died to not miss the extra Gold earned
            // await new Promise(resolve => this.setState({
            //     goldDifferenceOffset: this.getGoldOffset(liveStats.killerTeamID),
            //     goldDifference: 0
            // }, resolve));
            if (getCurrentTimestampSeconds() < (getTimeSec(liveStats) + timerLengthSeconds['buffBaron'])) {
                runTimerAndDisplay(timerName, 'buffBaron', liveStats);
            }
            nextSpawnGameTimes.baron = Math.round((liveStats.gameTime / 1000)) + constantRespawnTimes.baron;
            timerGameTimeTriggers['baron'] = false;
            timerGameTimeTriggers['baronLive'] = false;
            // TODO: GOLD DIFFERENCE
            // setTimeout(() => { this.listenGoldDifference(); }, 500);
        }
            break;
        case 'riftHerald':
            // setHide('heraldLive');
            nextSpawnGameTimes.herald = Math.round((liveStats.gameTime / 1000)) + constantRespawnTimes.herald;
            timerGameTimeTriggers['herald'] = false;
            timerGameTimeTriggers['heraldLive'] = false;
            break;
        default:
            break;
    }
};



const getCurrentGameTime = () : number => {
    if(!gameActive || isPaused){
        return currentGameTime;
    }
    if(pauseEndTime > 0){
        gameTimeAtStart = currentGameTime;
        gameStartTime = Date.now();
        pauseStartTime = 0;
        pauseEndTime = 0;
    }else{
        currentGameTime = Math.floor((Date.now() - gameStartTime)/1000) + gameTimeAtStart;
    }

    processTimerDisplays();
    
    return currentGameTime;
}

const processTimerDisplays = () => {
    if(currentGameTime > nextDragonSpawnGameTime){
        timerGameTimeTriggers['dragonLive'] = true;
    }else if((nextDragonSpawnGameTime - timerLengthSeconds['dragon']) < currentGameTime){
        timerGameTimeTriggers['dragon'] = true;
    }

    if(currentGameTime > nextSpawnGameTimes.baron){
        timerGameTimeTriggers['baronLive'] = true;
    }else if(currentGameTime > (nextSpawnGameTimes.baron - timerLengthSeconds['baron'])){
        timerGameTimeTriggers['baron'] = true;
    }else if(currentGameTime > nextSpawnGameTimes.herald){
        timerGameTimeTriggers['heraldLive'] = true;
    }else if(currentGameTime > (nextSpawnGameTimes.herald - timerLengthSeconds['herald'])){
        timerGameTimeTriggers['herald'] = true;
    }

    if(visibleTimers['buffBaron100']){
        if(currentGameTime > timerEndTimeGameSeconds['buffBaron100']){
            visibleTimers['buffBaron100'] = false;
        }
    }
    if(visibleTimers['buffBaron200']){
        if(currentGameTime > timerEndTimeGameSeconds['buffBaron200']){
            visibleTimers['buffBaron200'] = false;
        }
    }
    if(visibleTimers['buffElder100']){
        if(currentGameTime > timerEndTimeGameSeconds['buffElder100']){
            visibleTimers['buffElder100'] = false;
        }
    }
    if(visibleTimers['buffElder200']){
        if(currentGameTime > timerEndTimeGameSeconds['buffElder200']){
            visibleTimers['buffElder200'] = false;
        }
    }

    if(visibleTimers['inhibitor100']){
        if(currentGameTime > (timerEndTimeGameSeconds['inhibitor100Top'] || 0)
            && currentGameTime > (timerEndTimeGameSeconds['inhibitor100Mid'] || 0)
            && currentGameTime > (timerEndTimeGameSeconds['inhibitor100Bottom']) || 0){
                setHide('inhibitor100');
        }
    }
    if(visibleTimers['inhibitor200']){
        if(currentGameTime > (timerEndTimeGameSeconds['inhibitor200Top'] || 0)
            && currentGameTime > (timerEndTimeGameSeconds['inhibitor200Mid'] || 0)
            && currentGameTime > (timerEndTimeGameSeconds['inhibitor200Bottom']) || 0){
                setHide('inhibitor200');
        }
    }
}


const checkIfUpToDate = liveStatsEvent => {
    if (!realtimeListenerIsUpToDate && liveStatsEvent.date) {
        if (getCurrentTimestamp() - (Date.parse(liveStatsEvent.date)) <= 5000) { // 5s outdated?
            console.log('realtime listener is up to date.', liveStatsEvent.action);
            realtimeListenerIsUpToDate = true;
        }
    }
}

const pauseStartedFunction = async liveStats => {
    checkIfUpToDate(liveStats);
    console.log('pause started', liveStats);
    isPaused = true;
    pauseStartTime = Date.parse(liveStats.date);
};

const pauseEndedFunction = async liveStats => {
    checkIfUpToDate(liveStats);
    console.log('pause ended', liveStats);
    const resume = async () => {
        pauseEndTime = Date.parse(liveStats.date);
        isPaused = false;
        const pauseDelayMs = (pauseEndTime - pauseStartTime);
        // TODO: add this to all existing timers.
        // TODO: resume GAME TIME TIMER
    };
    if (!isPaused) {
        // TODO: resume GAME TIME TIMER
        // this.runGameTimer(Math.round(liveStats.gameTime + timeDiffAfterDelay), Date.now(), liveStats.gameTime === 0);
    } else {
        await resume();
    }
};

const getTimeDiff = timeMs => timeMs ? getCurrentTimestamp() - timeMs : getCurrentTimestamp();

const getCurrentTimestamp = () => {
    // return Date.now() + window.MainData.dataApiListener.component.dateSimulationOffsetMs;
    return Date.now();
};

const getCurrentTimestampSeconds = () => Math.round(Date.now()/1000);

const getTimeDiffSec = liveStats => {
    // let timeDiffMs = getTimeDiff(Date.parse(liveStats.date)) - (window.MainData.realtimeApi.liveStatusDelay * 1000);
    let timeDiffMs = getTimeDiff(Date.parse(liveStats.date));
    if (timeDiffMs < 0) {
        timeDiffMs = 0;
    }
    return Math.round(timeDiffMs / 1000);
};

const getTimeSec = liveStats => {
    return Math.round(Date.parse(liveStats.date)/1000);
}

const runTimerAndDisplay = (timerName : string, timerType : string, liveStats : any) => {
    timerEndTimeGameSeconds[timerName] = Math.round(liveStats.gameTime/1000) + timerLengthSeconds[timerType];
    // timerEndTimeSeconds[timerName] = ((liveStats && liveStats.date && (Math.round(Date.parse(liveStats.date)/1000))) || getCurrentTimestampSeconds()) + timerLengthSeconds[timerType];
    setVisible(timerName);
};
