export const paramMappings : any = {
    TestMatch: {
        test: (data : any) : boolean => data instanceof TestMatch,
        create: (data : any) : TestMatch => new TestMatch(data),
    },
    TestSchedule: {
        test: (data : any) : boolean => data instanceof TestSchedule,
        create: (data : any) : TestSchedule => new TestSchedule(data),
    },
    LjlPlayerInfos: {
        test: (data : any) : boolean => data instanceof LjlPlayerInfos,
        create: (data : any) : LjlPlayerInfos => new LjlPlayerInfos(data),
    }
}

export interface LjlDataStore {
    schedule?: any,
    realtimeApi?: {
        events?: {
            [platformIdGameId: string]: {
                team100: {
                    playerAccountIds : string[],
                },
                team200: {
                    playerAccountIds : string[],
                },
                stats: {
                    [playerAccountId: string]: {
                        positions: {
                            [gameTimeSeconds: number]: {
                                creepKilled: string[],
                                alive: any,
                            }
                        },
                        itemsHistory: {
                            transactionType: string,
                            item: string,
                            gameTime: number,
                        }[],
                        totalDamageDealtToChampions: {
                            [gameTimeSeconds: number] : number,
                        },
                        totalGold: number,
                        currentGold: number,
                        accountID: string,
                        totalDamageDealtToTurrets: number,
                        level: number,
                        XP: number,
                    }
                },
                teamStats: {
                    team100: any,
                    team200: any,
                },
                turretPlateStatus: {
                    team100: {
                        [lane: string]: number
                    },
                    team200: {
                        [lane: string]: number
                    },
                },
                jungleProximityProcessingStatus: any,
                junglerProximityData: {
                    [team100OrTeam200: string]: {
                        TOP: number[],
                        MID: number[],
                        BOT: number[],
                    }
                }
            }
        }
    }
}

interface LjlPlayerInfo {
    ProfileImg: {
        Square : string,
        Fullbody : string,
    },
    Name : string,
}

export const createLjlPlayerInfo = (name: string = '', squareImageUrl : string = '', fullBodyImageUrl: string = '') : LjlPlayerInfo => ({
    ProfileImg: {
        Square: squareImageUrl,
        Fullbody: fullBodyImageUrl,
    },
    Name: name,
});

export class LjlPlayerInfos {
    Top : LjlPlayerInfo = createLjlPlayerInfo()
    Jungle : LjlPlayerInfo = createLjlPlayerInfo()
    Mid : LjlPlayerInfo = createLjlPlayerInfo()
    Adc : LjlPlayerInfo = createLjlPlayerInfo()
    Support : LjlPlayerInfo = createLjlPlayerInfo()

    constructor(data: Partial<LjlPlayerInfos>) {
        Object.assign(this, data);
    }
}

export class ChampionMappings {
    Top: {ChampionUrl: string} = {ChampionUrl: ''}
    Jungle: {ChampionUrl: string} = {ChampionUrl: ''}
    Mid: {ChampionUrl: string} = {ChampionUrl: ''}
    Adc: {ChampionUrl: string} = {ChampionUrl: ''}
    Support: {ChampionUrl: string} = {ChampionUrl: ''}

    constructor(data: Partial<ChampionMappings>) {
        Object.assign(this, data);
    }
}

export class CurrentMatchTeam {
    LogoUrl: string
    Tricode: string
    WinRecord: number
    LossRecord: number
    constructor(data: Partial<CurrentMatchTeam>) {
        Object.assign(this, data);
    }
}

export class StandingsInput {
    RankLabel : string
    Tricode : string
    WinRecord : string
    LossRecord : string
    constructor(data: Partial<StandingsInput>) {
        Object.assign(this, data);
    }
}

export class StandingsEntry extends StandingsInput{
    TeamName : string
    LogoUrl : string
    constructor(data: Partial<StandingsEntry>) {
        super(data);
        Object.assign(this, data);
    }
}

export class WinLoseRecord {
    Wins: number
    Losses: number
    constructor(data: Partial<WinLoseRecord>) {
        Object.assign(this, data);
    }
}

export class ScheduledMatch {
    MatchTime: string
    IsFinished: boolean
    Teams: {
        TeamName: string
        TeamTricode: string
        LogoUrl: string
        Score: number
        IsWinner: boolean
    }[] = []

    Games: {
        IsFinished: boolean
        WinnerIndex: number
    }[] = []

    constructor(data: Partial<ScheduledMatch>) {
        Object.assign(this, data);
    }
}

export class InputMatchData {
    IsFinished: boolean
    TeamScores: number[]
    WinningTeamIndex: number
    Games: {
        IsFinished: boolean
        WinnerIndex: number
    }[]
}

export class TimerData {
    ImgUrl: string
    isTimerVisible: boolean
    isLiveVisible: boolean
    endingGameTimeSeconds: number
    constructor(data: Partial<TimerData>) {
        Object.assign(this, data);
    }
}

export class TestMatch {
    team1Name: string = ''
    team2Name: string = ''
    time: string = ''
    constructor(data: Partial<TestMatch>) {
        Object.assign(this, data);
    }
}

export class TestSchedule {
    matches: TestMatch[]
    constructor(data: Partial<TestSchedule>) {
        const matches : TestMatch[] = [];
        data.matches && data.matches.forEach(match => {
            matches.push(new TestMatch(match));
        });
        this.matches = matches;
    }
}

export const matchesClasstype = (classType: string | undefined, data: any): boolean => {
    let matches = false;
    const testFunction : any = paramMappings[classType || ''];
    if(testFunction){
        matches = testFunction.test(data);
    }
    return matches;
}

export const paramTypes = ['string', 'number', 'boolean'].concat(Object.keys(paramMappings)).concat(['string[]', 'number[]', 'boolean[]']).concat(Object.keys(paramMappings).map(type => `${type}[]`));
