import { doRestCall } from '../../../utils/rest';
import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';

const getRawStatsEndpoint = (platformID, gameID, paginationToken): string =>
    `/livestatsRaw/v1/platformGames/${platformID}_${gameID}/events${(paginationToken && `?paginationToken=${paginationToken}`) || ''}`


const devApiKey = '03Db0IJhVv8rGB479IzIS93u6R7pFRPlD78fsli0';
const prodApiKey = 'g9Y4JY7Ouh9ZGNgNr3iuC8DUVoiSRyAA4kunVZqZ';
// const prodHost = 'raw-stats-api.ewp.gg'; // real prod endpoint
// const prodHost = '192.168.0.109'; // local network pc
const prodHost = 'localhost'; // local pc
const devHost = 'localhost';
const isProd = true;

const apiKey = isProd ? prodApiKey : devApiKey;
const hostname = isProd ? prodHost : devHost;

let cachedResponse = {};
let cacheExpiryTime = 0;
let paginationToken = null;
const apiCallPeriodMs = 1000;
let finished = false;

let currentPlatformID = null;
let currentGameID = null;

const reset = () => {
    cachedResponse = {};
    paginationToken = null;
    finished = false;
    cacheExpiryTime = 0;
}

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "riotRealtimeApi",
        inputs: [{
            id: shortID(),
            name: "platformID",
            type: "string",
        },{
            id: shortID(),
            name: "gameID",
            type: "string",
        }],
        outputs: [{
            id: shortID(),
            name: "outputArray",
            type: "any[]"
        }],
    }),
    process: async (input: any[], nodeId: string, processedByLiveScreen : boolean) => {
        if(finished) return [[]];
        if (!input || input.length < 2) return [[]];
        if(!input[0] || input[0].length === 0) return [[]];
        if(!input[1] || input[1].length === 0) return [[]];

        if(cacheExpiryTime > Date.now()){
            return cachedResponse;
        }
        cacheExpiryTime = Date.now() + apiCallPeriodMs;

        if(currentPlatformID !== input[0]){
            currentPlatformID = input[0];
            reset();
        }
        if(currentGameID !== input[1]){
            currentGameID = input[1];
            reset();
        }

        const page = await callApi(input[0], input[1], paginationToken);
        const events = processApi(page);

        cachedResponse = [events];
        cacheExpiryTime = Date.now() + apiCallPeriodMs;
        return [events];
    },
}

const processApi = (page : any) => {
    let results = [];
    if (page && page.events && page.events.length > 0) {
        results = page.events;
        paginationToken = page.nextPageToken;
        // console.log('paginationToken', paginationToken);
    }

    if (finished) { // game is over when paginationToken is gone.
        console.log('Game Ended');
    }

    return results;
}

const callApi = async (platformID, gameID, paginationToken) => {
    return await doRestCall({
        hostname,
        port: (hostname.includes('localhost') || hostname.startsWith('192.168')) ? 5678 : 441,
        path: getRawStatsEndpoint(platformID, gameID, paginationToken),
        headers: {
            'x-api-key': apiKey,
        },
        method: 'GET',
    }, undefined, (hostname.includes('localhost') || hostname.startsWith('192.168')) ? true : false, 5000);

}