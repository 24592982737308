import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';

const alreadyProcessed = {

};

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "eventFilter",
        inputs: [{
            id: shortID(),
            name: "inputArray",
            type: "any[]"
        }, {
            id: shortID(),
            name: "idKey",
            type: "string"
        }],
        outputs: [{
            id: shortID(),
            name: "outputArray",
            type: "any[]"
        }],
    }),
    process: (input: any[], nodeId: string, processedByLiveScreen: boolean) => {
        if (!input || input.length < 2) return [null];
        try {
            if (!input[0]) return [null];
            if (input[0].length === 0) return [[]];

            let data;

            const key = input[1];
            if (!key) return [input[0]];

            if (!alreadyProcessed[nodeId]) {
                if (processedByLiveScreen) {
                    alreadyProcessed[nodeId] = {};
                }
                data = input[0];
            } else {
                data = input[0].filter(item => item[key] !== null && item[key] !== undefined && !alreadyProcessed[nodeId][item[key]]);
            }

            if (processedByLiveScreen) {
                data.forEach(item => {
                    // the nodeIds basically map to a hash set
                    alreadyProcessed[nodeId][item[key]] = true;
                });
            }

            // console.log('filtered events ', JSON.stringify(data));
            return [data];
        } catch (error) {
            console.log('error', error);
            return [null];
        }
    },
}
