import React, { Component } from 'react';
import { showLogin, isLoggedIn, logout } from '../../auth0';

import {
    Main,
    Title
} from './styles';

class Home extends Component {

    state = {
        loggedIn: false,
    };

    async componentDidMount() {
        const loggedIn = await isLoggedIn();
        this.setState({loggedIn});
    }

    render() {
        return (
        <Main className="Auth">
            <header className="App-header">
            <Title className="App-title">Welcome to DEKKI Broadcast</Title>
            </header>
            {
                !this.state.loggedIn ?
                <div>
                    <p>
                    To get started, LOG IN with your DEKKI Account
                    </p>
                    <button onClick={login}>LOGIN</button>
                </div>
                :
                <div>
                    <p>Logged in!</p>
                    <p><a href="/" onClick={logout}>Log Out</a></p>
                </div>
            }
        </Main>
        );
    }
}

const login = async () => {
  showLogin();
}

export default Home;
