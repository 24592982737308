import React from "react";
import Rete, { Node, NodeEditor } from "rete";
import ReactRenderPlugin from "rete-react-render-plugin";
import ConnectionPlugin from "rete-connection-plugin";
import AreaPlugin from "rete-area-plugin";
import ContextMenuPlugin from 'rete-context-menu-plugin';
import { ScreenGroup } from "../nodeSystem/ScreenGroup";
// import { NumControl, ConstantNumberComponent } from "./controls/constantNumberComponents"; 
import { SelectorComponent, FileComponent, ObjectPathComponent, JoinToArrayComponent, JsonToObjectComponent, MockApiComponent, SwapInputsComponent,
  EventFilterComponent, LogicalOrComponent, RiotRealtimeApiComponent, LjlJsonTeamProcessorComponent, LjlJsonScheduleProcessorComponent,
  LjlStandingsComponent, LolChampionMapperComponent, LolGameScreenComponent,
} from "./controls/uiNodeComponents";
import { NumberComponent, RefComponent, UiNodeConstantComponent, StringComponent, BooleanComponent, JsonConstantComponent } from "./controls/uiNodeConstantComponents";
import { ScreenComponent } from "./controls/screenComponent";
import { ConstantNode, ProcessingNode, Screen } from "../nodeSystem/defs";
import constantRef, { dataSourceReferenceType } from "../nodeSystem/nodeTypes/constantRef";
import { createNode } from "../nodeSystem/nodeTypes";
import ljlStandings from "../nodeSystem/nodeTypes/ljlStandings";

const setupComponents = (screenGroup : ScreenGroup, editor: NodeEditor) => {
  const nodeMapping = {};
  const screenMapping = {};
  const components = [
    new NumberComponent(screenGroup),
    new StringComponent(screenGroup),
    new JsonConstantComponent(screenGroup),
    new BooleanComponent(screenGroup),
    new RefComponent(screenGroup),
    new SelectorComponent(screenGroup),
    new JoinToArrayComponent(screenGroup),
    new JsonToObjectComponent(screenGroup),
    new FileComponent(screenGroup),
    new ObjectPathComponent(screenGroup),
    new EventFilterComponent(screenGroup),
    new LogicalOrComponent(screenGroup),
    new MockApiComponent(screenGroup),
    new RiotRealtimeApiComponent(screenGroup),
    new LjlJsonTeamProcessorComponent(screenGroup),
    new LjlJsonScheduleProcessorComponent(screenGroup),
    new LjlStandingsComponent(screenGroup),
    new LolChampionMapperComponent(screenGroup),
    new LolGameScreenComponent(screenGroup),
    new SwapInputsComponent(screenGroup),
  ];
  for(let i=0;i < components.length;i++){
    if(components[i] instanceof UiNodeConstantComponent){
      nodeMapping[(createNode(components[i].type) as ConstantNode).constantType] = components[i];
    }else{
      nodeMapping[components[i].type] = components[i];
    }
  }
  const screens = [
    new ScreenComponent('Starters Screen', screenGroup, 'Starters', ['Screens', 'LJL']),
    // new ScreenComponent('Vmix Triggers', screenGroup, 'VmixTriggers'),
    new ScreenComponent('Schedule Screen', screenGroup, 'Schedule', ['Screens', 'LJL']),
    new ScreenComponent('Matchup Screen', screenGroup, 'Matchup', ['Screens', 'LJL']),
    new ScreenComponent('Standings Screen', screenGroup, 'Standings', ['Screens', 'LJL']),
    new ScreenComponent('Game Screen', screenGroup, 'GameScreenLOL', ['Screens', 'LJL']),
  ];
  for(let i=0;i < screens.length;i++){
    screenMapping[screens[i].type] = screens[i];
  }
  components.forEach(c => {
    editor.register(c);
  });
  screens.forEach(s => {
    editor.register(s);
  });
  return {
    nodeMapping,
    screenMapping,
  };
};

export async function createEditor(container, screenGroup : ScreenGroup, existingEditor, setEditor, componentID: string, setAddRefNode, setDeleteRefNodes, refreshUi) {
  if(existingEditor){ 
    if( existingEditor.id === `${componentID}@0.1.0`) return;
    existingEditor.destroy();
  }
  container.innerHTML  = "";
  // TODO: organize the component list

  const reteId = `${componentID}@0.1.0`;
  var editor = new NodeEditor(reteId, container);
  const {nodeMapping, screenMapping} = setupComponents(screenGroup, editor);
  setEditor(editor);
  editor.use(ConnectionPlugin);
  editor.use(ReactRenderPlugin);

  const inputsMap = {};
  const outputsMap = {};

  const populateInputsOutputs = (node : Node) => {
    node.inputs.forEach(input => {
      inputsMap[input.key] = input;
    });
    node.outputs.forEach(output => {
      console.log('output', output);
      outputsMap[output.key] = output;
    });
  };

  /** ************* Populate Nodes from loaded Json. ************** **/
  for(let i=0;i < screenGroup.data.nodes.length;i++){
    const node = screenGroup.data.nodes[i];
    let nodeDef;
    if(node.isConstant){
      nodeDef = nodeMapping[(node as ConstantNode).constantType];
    }else{
      nodeDef = nodeMapping[node.type];
    }
    if(nodeDef === undefined || nodeDef === null) continue;
    const n =await nodeDef.createNode({screenGroup, node});
    
    n.position = [(n.data as any).node.posX as number, (n.data as any).node.posY as number];
    editor.addNode(n);
    populateInputsOutputs(n);
  }
  /** ************* Populate Screens from loaded Json. ************** **/
  for(let i=0;i < screenGroup.data.screens.length;i++){
    const screen = screenGroup.data.screens[i];
    const screenDef = screenMapping[screen.type];
    const s = await screenDef.createNode({screenGroup, screen});
    s.position = [1000, i * 350];
    editor.addNode(s);
    populateInputsOutputs(s);
  }
  /** ************* Populate Connections from loaded Json. ************** **/
  const toIds = Object.keys(screenGroup.data.connections);
  for(let i=0;i < toIds.length;i++){
    const toId = toIds[i];
    console.log('connecting to output ', screenGroup.data.connections[toId]);
    editor.connect(outputsMap[screenGroup.data.connections[toId]], inputsMap[toId]);
  }

  /** Event logic **/
  editor.on("nodecreate", (node) => {
    if(node.data.screen){
      node.position = [1000, (screenGroup.data.screens.length-1) * 350];
    }
    return true;
  });
  editor.on("nodecreated", (node) => {
    if(node.data.screen){
      AreaPlugin.zoomAt(editor, editor.nodes);
    }
  });
  editor.on("connectioncreate", (conn) => {
    console.log(`from ${conn.output.key} to ${conn.input.key}`);
    return screenGroup.attachDataItems(conn.output.key, conn.input.key);
  });
  editor.on("connectionremove", (conn) => {
    console.log('remove', conn);
    return screenGroup.detachDataItem(conn.input.key);
  });
  editor.on("noderemove", (node) => {
    // console.log('delete node', node.data.node);
    if(node.data.screen){
      return screenGroup.removeScreen((node.data as any).screen.id);
    }
    return screenGroup.deleteNode((node.data as any).node.id);
  });
  editor.on("nodetranslate", ({node, x, y}) => {
    if(node.data.screen){
      // Currently cannot move screen nodes.
      return false;
    }
    return screenGroup.moveNode((node.data as any).node.id, x, y);
  });

  console.log('editor made');

  const screenList = Object.values(screenMapping);

  editor.use(ContextMenuPlugin, {
    searchBar: true,
    delay: 100,
    allocate(component) {
      if(component === nodeMapping[dataSourceReferenceType]) return false;
      if(component.submenu){
        return component.submenu;
      }
      if (screenList.includes(component)) return ['Screens'];
        // component = () => component.createNode({screenGroup, node: screenGroup.addNode('splitSchedule', 200, 200)});
        return ['Nodes'];
    },
    rename(component) {
        return component.name;
    },
    items: {
        'Open View in Tab'(){
            try{
              window.open(`${process.env.REACT_APP_SITE_URL || 'http://localhost:3000'}/viewer/${screenGroup.data && screenGroup.data.id}`);
            }catch(error){
              console.log(error);
            }
        },
        'Open Triggers in Tab'(){
          try{
            window.open(`${process.env.REACT_APP_SITE_URL || 'http://localhost:3000'}/viewer/${screenGroup.data && screenGroup.data.id}/triggers`);
          }catch(error){
            console.log(error);
          }
        },
        'Debug Log'(){ console.log('Works!', screenGroup.getJson(), `Active screen: ${screenGroup.getCurrentActiveScreenId()}` ) },
    },
    nodeItems: node => {
      const options = {
        'See Data': async event => {
          const node : ProcessingNode = event.node.data.node;
          if(node){
            const data = await screenGroup.getDataForNode(node.id, false);
            alert(JSON.stringify(data));
          }else{
            const screen = event.node.data.screen;
            if(screen){
              const inputs = await screenGroup.getDataForScreen(screen.id, false);
              alert(JSON.stringify({inputs}));
            }
          }
        },        
        'Delete': true, 
        'Clone': false // dont show Clone item
      };
      if(node.data.screen){
        if(screenGroup.getCurrentActiveScreenId() === node.data.screen.id){
          options['Turn Off'] = async event => {
              screenGroup.setCurrentActiveScreen(null);
              refreshUi();
          }
        }else{
          if(node.data.screen.type === 'VmixTriggers'){
              if(node.data.screen.status){
                options['Turn Off'] = async event => {
                  const screen = event.node.data.screen;
                  if(screen){
                    screen.status = false;
                    console.log('triggers deactivated', screen.id);
                    refreshUi();
                  }
                };
              }else{
                options['Turn On'] = async event => {
                  const screen = event.node.data.screen;
                  if(screen){
                    screen.status = true;
                    console.log('triggers activated', screen.id);
                    processVmixTriggers(screenGroup, screen);
                    refreshUi();
                  }
                };
              }
          }else{
            options['Go Live'] = async event => {
                const screen = event.node.data.screen;
                if(screen){
                  screenGroup.setCurrentActiveScreen(screen.id);
                  console.log('screen set ', screen.id);
                  refreshUi();
                }
            };
          }
        }
      }
      return options;
    },
  });

  editor.view.resize();
  editor.trigger("process");
  AreaPlugin.zoomAt(editor, editor.nodes);

  setAddRefNode({
    async run(id: string){
      const nodeDef = nodeMapping[dataSourceReferenceType];
      console.log('screen', screenGroup, nodeDef);
      const nod = screenGroup.addNode(dataSourceReferenceType, 100, 100, id);
      console.log('nod', nod);
      const n = await nodeDef.createNode({screenGroup, node: nod});
      n.position = [100, 100];
      editor.addNode(n);
      populateInputsOutputs(n);
    } 
  });

  setDeleteRefNodes({
    async run(dataSourceId: string){
      const deletedNodeIds = screenGroup.removeDataSourceInput(dataSourceId);
      const toDelete = [];
      editor.nodes.forEach(node => {
        if(node.data.node){
          if(deletedNodeIds.includes((node.data as any).node.id)){
            toDelete.push(node);
          }
        }
      });
      toDelete.forEach(node => editor.removeNode(node));
    }
  })
};

const processVmixTriggers = (screenGroup : ScreenGroup, nodeScreen: {id: string, status: string}) => {
  setTimeout(async () => {
    const screenData = await screenGroup.getDataForScreen(nodeScreen.id, true);
    console.log("processing screenData for triggers" + screenData);
    if(nodeScreen.status){
      processVmixTriggers(screenGroup, nodeScreen);
    }
  }, 50);
}

