import _ from 'lodash';

const supportedLangLocales = {
  'en': 'en-US',
  'ja': 'ja-JP',
  'ko': 'ko-KR',
  'zh': 'zh-TW',
}

export const getLanguage = () => {
  let lang;
  try{
    const path = window.location.pathname.substring(1).split('/');
    lang = path[0];
  }catch(error){
    lang = 'en';
  }
  const langs = Object.keys(supportedLangLocales);
  const locales = _.values(supportedLangLocales);
  for (let i = 0; i < 2; i++) {
    if (lang) {
      if (langs.includes(lang)) {
        return lang;
      }
      if (locales.includes(lang)) {
        return lang.split('-')[0];
      }
    }
    lang = navigator.language;
  }
  return 'en';
}

export const getLocale = () => {
  let lang;
  try{
    const path = window.location.pathname.substring(1).split('/');
    lang = path[0];
  }catch(error){
    lang = 'en';
  }
  const langs = Object.keys(supportedLangLocales);
  const locales = _.values(supportedLangLocales);
  for (let i = 0; i < 2; i++) {
    if (lang) {
      if (langs.includes(lang)) {
        return supportedLangLocales[lang];
      }
      if (locales.includes(lang)) {
        return lang;
      }
    }
    lang = navigator.language;
  }
  return 'en-US';
}
