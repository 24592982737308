import React from 'react';
import Rete from "rete";
import { ScreenGroup } from '../../nodeSystem/ScreenGroup';
import { genericSocket } from './socketTypes';
import { NumControl, StringControl, ReadonlyLabelControl, BooleanControl, TextAreaControl } from './constantControls';
import { dataSourceReferenceType } from '../../nodeSystem/nodeTypes/constantRef';
import { DataItem } from '../../nodeSystem/defs';


 export class UiNodeConstantComponent extends Rete.Component {
    group : ScreenGroup;
    type : string
    constructor(name: string, group : ScreenGroup, type : string) {
      super(name);
      this.group = group;
      this.type = type;
    }
  
    builder(node) {
      // console.log('node ui data', node);
      var response = node;
      let backendNode;
      if(node.data.node){
        backendNode = node.data.node;
      }else{
        backendNode = this.group.addNode(this.type,0,0);
      }
      if(backendNode.constantType === dataSourceReferenceType){
        const item = this.group.data.inputs.find((input : DataItem) => input.id === backendNode.value);
        if(item){
          response = response.addOutput(new Rete.Output(backendNode.id, `(${item.type})`, genericSocket));
        }
      }else{
        response = response.addOutput(new Rete.Output(backendNode.id, `(${backendNode.constantType})`, genericSocket));
      }
      
      console.log('new node', backendNode);
      node.data = {
        screenGroup: this.group,
        node : backendNode,
      };
      return response;
    }
  
    worker(node, inputs, outputs) {
      // outputs["num"] = node.data.num;
    }
  }

  export class NumberComponent extends UiNodeConstantComponent {
    constructor(group : ScreenGroup) {
      super("Number", group, 'constantNumber');
    }
    builder(node) {
      const response = super.builder(node);
      var ctrl = new NumControl(this.editor, node.data.node.id, node);

      return response.addControl(ctrl);
    }
  }

  export class StringComponent extends UiNodeConstantComponent {
    constructor(group : ScreenGroup) {
      super("String", group, 'constantString');
    }
    builder(node) {
      const response = super.builder(node);
      var ctrl = new StringControl(this.editor, node.data.node.id, node);

      return response.addControl(ctrl);
    }
}
  
export class JsonConstantComponent extends UiNodeConstantComponent {
  constructor(group : ScreenGroup) {
    super("JSON Raw", group, 'constantJson');
  }
  builder(node) {
    const response = super.builder(node);
    var ctrl = new TextAreaControl(this.editor, node.data.node.id, node);

    return response.addControl(ctrl);
  }
}

  export class BooleanComponent extends UiNodeConstantComponent {
    constructor(group : ScreenGroup) {
      super("Boolean", group, 'constantBoolean');
    }
    builder(node) {
      const response = super.builder(node);
      var ctrl = new BooleanControl(this.editor, node.data.node.id, node);

      return response.addControl(ctrl);
    }
  }

  export class RefComponent extends UiNodeConstantComponent {
    constructor(group : ScreenGroup) {
      super("Variable Input", group, 'constantRef');
    }
    builder(node) {
      const response = super.builder(node);

      var ctrl = new ReadonlyLabelControl(this.editor, node.data.node.id, node);
      return response.addControl(ctrl);
    }
  }

