import styled from 'styled-components';
import {colors} from '../../styles/vars';

export const GlobalContainer=styled.div`
    background-color: ${colors.grey.dark};
    min-height: 100%;
`;

export const Menu = styled.div`
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin: 10px;
    background-color: ${colors.grey.dark};

    `;

export const MenuItems = styled.p`

    a {
        color: ${colors.blue.normal};
        margin: 0 10px;
    
        &:hover {
            color: ${colors.blue.light};
        }
    }
    
    span {
        color: ${colors.grey.lighter};
        margin: 0 10px;
        font-weight: 300;
    
        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin: 0 5px 0 7px;
            vertical-align: middle;
        }
    
        strong {
            font-weight: 500;
            color: ${colors.text.normal};
        }
    }

`;

export const Logo = styled.img`
    height: 30px;
    vertical-align: middle;
    margin-right: 10px;
`;