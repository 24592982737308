import _ from 'lodash';
import momentJA from './moment/ja';
import momentKO from './moment/ko';
import momentZHTW from './moment/zh-tw';
import { getLanguage } from '../utils/language';

export const LANGUAGE =
  (getLanguage() === 'zh' ? 'zh-tw' : getLanguage()) || 'en';

// const i18n = require(`./lang/${LANGUAGE}.json`);
// TODO:
const i18n = {
  locale: 'en-US',
};

if (i18n.locale === 'ja-JP') momentJA();
else if (i18n.locale === 'ko-KR') momentKO();
else if (i18n.locale === 'zh-TW') momentZHTW();

const locales = ['en-US', 'ja-JP', 'ko-KR', 'zh-TW'];

export const LOCALE = locales.indexOf(i18n.locale) > -1 ? i18n.locale : 'en-US';

export const lookup = obj => (path, fallback) => {
  const s = _.get(obj, path, path);
  if (s === path) {
    const env = process.env.REACT_APP_ENVIRONMENT || 'local';
    if (env === 'local') console.warn(`i18n.${path} not found`);
    if (fallback !== undefined) return fallback;
  }
  return s;
};

export default lookup(i18n);
