import shortID from '../../../utils/shortID';
import { ConstantNode } from '../../defs';

export default {
    create: () => new ConstantNode({
        id: shortID(),
        constantType: 'any',
        value: '',
    }),
}
