import { Screen } from '../defs';
import shortID from '../../utils/shortID';

const typeToInputMappings: any = {
    TestScreen: [{
        name: 'TestScreenString',
        type: 'string',
    }, {
        name: 'TestScreenSchedule',
        type: 'TestSchedule',
    }, {
        name: 'TestScreenNumber',
        type: 'number',
    }],
    VmixTriggers: [{
        name: 'BaronTimer',
        type: 'boolean',
    }],
    Starters: [{
        name: 'TeamsRoster',
        type: 'LjlPlayerInfos[]',
    },{
        name: 'ShowHide',
        type: 'boolean',
    },{
        name: 'ShowNextTeam',
        type: 'boolean',
    }],
    Matchup: [{
        name: 'TeamsRoster',
        type: 'LjlPlayerInfos[]',
    },{
        name: 'Picks',
        type: 'ChampionMappings[]',
    }],
    Schedule: [{
        name: 'CurrentMatch',
        type: 'CurrentMatchTeam[]',
    },{
        name: 'Schedule',
        type: 'ScheduledMatch[]',
    }],
    Standings: [{
        name: 'Standings',
        type: 'StandingsEntry[]',
    }],
    GameScreenLOL: [{
        name: 'bestOf',
        type: 'number',
    },{
        name: 'gameStarted',
        type: 'boolean',
    },{
        name: 'gameTimeSeconds',
        type: 'number',
    },{
        name: 'gamePaused',
        type: 'boolean',
    },{
        name: 'Drake',
        type: 'TimerData',
    }, {
        name: 'HeraldSpawn',
        type: 'TimerData',
    }, {
        name: 'BaronSpawn',
        type: 'TimerData',
    }, {
        name: 'ElderBuff100',
        type: 'TimerData',
    }, {
        name: 'ElderBuff200',
        type: 'TimerData',
    }, {
        name: 'BaronBuff100',
        type: 'TimerData',
    }, {
        name: 'BaronBuff200',
        type: 'TimerData',
    },{
        name: 'inhibitor100Visible',
        type: 'boolean',
    },{
        name: 'inhibitor200Visible',
        type: 'boolean',
    },{
        name: 'inhibitor100TopEndTimeSeconds',
        type: 'number',
    },{
        name: 'inhibitor100MidEndTimeSeconds',
        type: 'number',
    },{
        name: 'inhibitor100BottomEndTimeSeconds',
        type: 'number',
    },{
        name: 'inhibitor200TopEndTimeSeconds',
        type: 'number',
    },{
        name: 'inhibitor200MidEndTimeSeconds',
        type: 'number',
    },{
        name: 'inhibitor200BottomEndTimeSeconds',
        type: 'number',
    },{
        name: 'Bans',
        type: 'string[]',
    },{
        name: 'realtimeListenerIsUpToDate',
        type: 'boolean',
    },{
        name: 'nextDragonType',
        type: 'string',
    },{
        name: 'nextDragonSubType',
        type: 'string',
    },{
        name: 'dragonTokens',
        type: 'any',
    }]
};

export const createScreen = (name: string, screenType: string): Screen => {
    const inputs = typeToInputMappings[screenType] || [];
    return new Screen({
        id: shortID(),
        type: screenType,
        name,
        inputs: inputs.map((input: any) => ({
            ...input,
            id: shortID(),
        })),
    });
};
