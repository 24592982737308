import toUpperCase from './toUpperCase';
import toLowerCase from './toLowerCase';
import constantBoolean from './constantBoolean';
import constantString from './constantString';
import constantJson from './constantJson';
import constantTestSchedule from './constantTestSchedule';
import constantRef from './constantRef';
import constantNumber from './constantNumber';
import joinToArray from './joinToArray';
import jsonToObject from './jsonToObject';
import objectPath from './objectPath';
import eventFilter from './eventFilter';
import logicalOr from './logicalOr';
import lolChampionMapper from './lolChampionMapper';
import riotRealtimeApi from './riotRealtimeApi';
import lolGameScreen from './lolGameScreen';
import swapInputs from './swapInputs';
import ljlJsonTeamProcessor from './ljlJsonTeamProcessor';
import ljlJsonScheduleProcessor from './ljlJsonScheduleProcessor';
import ljlStandings from './ljlStandings';
import mockApi from './mockApi';
import file from './file';
import selector from './selector';
import { ProcessingNode, DataItem } from '../defs';
import { matchesClasstype } from '../paramTypes';

const typeMappings: any = {
    toUpperCase,
    toLowerCase,
    constantBoolean,
    constantString,
    constantJson,
    constantNumber,
    constantRef,
    constantTestSchedule,
    selector,
    file,
    objectPath,
    joinToArray,
    jsonToObject,
    eventFilter,
    logicalOr,
    lolChampionMapper,
    lolGameScreen,
    mockApi,
    riotRealtimeApi,
    ljlJsonTeamProcessor,
    ljlJsonScheduleProcessor,
    ljlStandings,
    swapInputs,
};

export const processNode = async (data: ProcessingNode, inputData: any[], processedByLiveScreen = true) => {
    let outputs;

    const logic: any = typeMappings[data.type];
    if (logic) {
        outputs = await logic.process(inputData, data.id, processedByLiveScreen);
    }

    // if (!validateDataItems(data.outputs, outputs)) {
    //     console.log('error bad type');
    //     return null;
    // }
    return outputs;
};

export const createNode = (nodeType: string) : ProcessingNode => {
    const logic: any = typeMappings[nodeType];
    if (logic) {
        return logic.create();
    }
    return null;
};

// export const validateDataItems = (definitions: DataItem[], data: any[]) => {
//     for (let i = 0; i < definitions.length; i++) {
//         if (typeof (data[i]) !== definitions[i].type && !matchesClasstype(definitions[i].type, data[i])) {
//             return false;
//         }
//     }
//     return true;
// };
