import React from "react";

export default ({TeamsRoster, ShowHide, ShowNextTeam}: {TeamsRoster : any, ShowHide: boolean, ShowNextTeam: boolean}) => {
  
  return (
    <React.Fragment>
      <div style={{color: 'white'}}>
        <p>STARTERS</p>
        <p>{ ShowHide && (ShowNextTeam ? team(TeamsRoster, 1) : team(TeamsRoster, 0))}</p>
      </div>
    </React.Fragment>
  );
};

const team = (teams, arrayIndex) => {
  if(teams && teams[arrayIndex]){
    return `Team ${teams[arrayIndex].name} ${Object.keys(teams[arrayIndex].roster).join(' ')}`;
  }
  return '';
}
