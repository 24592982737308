import React from 'react';
import Rete from "rete";
import { ProcessingNode, DataItem } from '../../nodeSystem/defs';
import { ScreenGroup } from '../../nodeSystem/ScreenGroup';
import { Screen } from '../../nodeSystem/defs';
import { genericSocket } from './socketTypes';
import { LiveLabelControl, TriggersLabelControl } from './constantControls';

export class ScreenComponent extends Rete.Component {
  group: ScreenGroup;
  type: string
  submenu: string[]
  constructor(name: string, group: ScreenGroup, type: string, submenu:string[] = null) {
    super(name);
    this.group = group;
    this.type = type;
    this.submenu = submenu;
  }

  builder(node) {
    // console.log('node ui data', node);
    var response = node;
    let backendScreen : Screen;
    if (node.data.screen) {
      backendScreen = node.data.screen;
    } else {
      backendScreen = this.group.addScreen(this.name, this.type);
    }
    const inputs = backendScreen.inputs;

    if (inputs && inputs.length > 0) {
      inputs.forEach((input: DataItem) => {
        response = response.addInput(new Rete.Input(input.id, `${input.name}(${input.type})`, genericSocket));
      });
    }

    console.log('new screen', backendScreen);
    node.data = {
      screenGroup: this.group,
      screen: backendScreen,
    };
    let ctrl = null;
    if(node.data.screen.type !== 'VmixTriggers'){
      ctrl = new LiveLabelControl(this.editor, node.data.screen.id, node);
    }else{
      ctrl = new TriggersLabelControl(this.editor, node.data.screen.id, node);
    }
    

    return response.addControl(ctrl);
    // return response;
  }

  worker(node, inputs, outputs) {
    // outputs["num"] = node.data.num;
  }
}