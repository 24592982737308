import { get, set, range } from 'lodash';
import { stateBasedEvents } from '.';
import { LjlDataStore } from '../../paramTypes';
import { defineCreep } from './realtimeStatsProcessingService';

const playerPositions = [
    'top',
    'jungle',
    'mid',
    'adc',
    'support',
];

/**
 * 
 * @param liveStats 
 * @param dataStore 
 * @param processedEvents 
 */
export const processSendData = async (liveStats : any, dataStore : LjlDataStore, processedEvents : any, stateBasedEventsIds : any, actionCallbacks : {[action : string]: (liveStats: any) => void}) => {
    const gameID = liveStats.gameID;
    const platformID = liveStats.platformID;
    const platformIdGameId = `${platformID}_${gameID}`;
    if (!processedEvents[platformIdGameId]) {
        processedEvents[platformIdGameId] = {
            list: {},
        };
    }
    if (liveStats.action === 'stats_update') {
        processStatsUpdate(liveStats, dataStore, platformIdGameId);
        // if (callbacks[liveStats.action]) {
        //     callbacks[liveStats.action].forEach(callback => callback(liveStats));
        // }
    } else {
        if (stateBasedEvents.includes(liveStats.action)) {
            if (stateBasedEventsIds[liveStats.action]) {
                delete processedEvents[platformIdGameId].list[stateBasedEventsIds[liveStats.action]];
            }
            stateBasedEventsIds[liveStats.action] = parseInt(liveStats.sequenceIndex, 10);
        }
        processedEvents[platformIdGameId].list[parseInt(liveStats.sequenceIndex, 10)] = liveStats;

        // if (callbacks[liveStats.action]) {
        //     callbacks[liveStats.action].forEach(callback => callback(liveStats));
        // }
        switch (liveStats.action) {
            case 'champ_select':
                populateTeamData(liveStats, dataStore, platformIdGameId);
                break;
            case 'game_info':
                populatePerkData(liveStats, dataStore, gameID, platformID);
                populateGameInfo(liveStats, dataStore, gameID, platformID);
                break;
            case 'turret_plate_destroyed':
                console.log("turret plate destroyed", liveStats);
                updateTurretPlateStatus(liveStats, dataStore, platformIdGameId);
                // this.updateGoldEarnedFromTurretPlateDestruction(liveStats);
                break;
            case 'epic_monster_kill': {
                const creepType = defineCreep(liveStats.monsterType, liveStats.position);
                if(creepType){
                    console.log('creep event', liveStats);
                    const playerAccountId = getPlayerAccountIDFromParticipantID(liveStats.killer, dataStore, platformIdGameId);
                    await updatePlayersStatsCreepsKills(creepType, playerAccountId, parseInt(liveStats.gameTime), dataStore, platformIdGameId);
                }
                break;
            }
            case 'skill_level_up':
                break;
            case 'champion_level_up':
                break;
            case 'champion_kill':
                break;
            case 'item_destroyed':
            case 'item_purchased':
            case 'item_sold':
            case 'item_undo':{
                const playerAccountId = getPlayerAccountIDFromParticipantID(liveStats.participantID, dataStore, platformIdGameId);
                await updateItemMovement(liveStats.action, playerAccountId, liveStats.itemID, parseInt(liveStats.gameTime), dataStore, platformIdGameId);
                break;
            }

            default:
                console.log('XYZ ',liveStats.action, liveStats);
                break;
        }
        if(actionCallbacks[liveStats.action]){
            actionCallbacks[liveStats.action](liveStats);
        }
    }

    // sendToDekkiFrameIfNeeded(liveStats); // TODO:
}


const populateTeamData = (liveStats : any, dataStore : any, platformIdGameId: string) => {
    console.log('team data 1');
    if (!liveStats.teamOne || !liveStats.teamTwo) return;
    console.log('team data 2');

    let tricode100, tricode200;

    const mainDatateam100 =dataStore.schedule.rounds[dataStore.schedule.round].matches[dataStore.schedule.match].team100;
    const mainDatateam200 = dataStore.schedule.rounds[dataStore.schedule.round].matches[dataStore.schedule.match].team200;
    console.log('team data 3');

    // const expectedTricodes = [mainDatateam100.tricode, mainDatateam200.tricode];

    // tricode100 = calculateTricode(expectedTricodes, Object.values(liveStats.teamOne).map(player => player.summonerName));
    // tricode200 = calculateTricode(expectedTricodes, Object.values(liveStats.teamTwo).map(player => player.summonerName));

    // if (!tricode100) {
        const gameTeams = dataStore.schedule.rounds[dataStore.schedule.round].matches[dataStore.schedule.match].games[dataStore.schedule.game].teams;
        const reversed = '200' === (gameTeams[mainDatateam100.tricode] && gameTeams[mainDatateam100.tricode].side);
        console.log('reversed', reversed);
        if (reversed) {
            tricode200 = mainDatateam100.tricode;
            tricode100 = mainDatateam200.tricode;
        } else {
            tricode100 = mainDatateam100.tricode;
            tricode200 = mainDatateam200.tricode;
        }
    // }

    const teamOne = liveStats.teamOne;
    const existingTeam100Players = get(dataStore, `realtimeApi.events.${platformIdGameId}.team100.players`);
    if (existingTeam100Players) {
        existingTeam100Players.forEach(p => {
            if (p.participantID && p.accountID) {
                if (teamOne[p.accountID]) {
                    teamOne[p.accountID].participantID = p.participantID;
                }
            }
        });
    }
    const teamTwo = liveStats.teamTwo;
    const existingTeam200Players = get(dataStore, `realtimeApi.events.${platformIdGameId}.team200.players`);
    if (existingTeam200Players) {
        existingTeam200Players.forEach(p => {
            if (p.participantID && p.accountID) {
                if (teamTwo[p.accountID]) {
                    teamTwo[p.accountID].participantID = p.participantID;
                }
            }
        });
    }

    set(dataStore, `realtimeApi.events.${platformIdGameId}.team100`, Object.assign(get(dataStore, `realtimeApi.events.${platformIdGameId}.team100`) || {}, {
        tricode: tricode100,
        players: Object.values(teamOne),
        playerAccountIds: Object.values(teamOne).map((player : any) => player.accountID),
    }));
    set(dataStore, `realtimeApi.events.${platformIdGameId}.team200`, Object.assign(get(dataStore, `realtimeApi.events.${platformIdGameId}.team00`) || {}, {
        tricode: tricode200,
        players: Object.values(teamTwo),
        playerAccountIds: Object.values(teamTwo).map((player : any) => player.accountID),
    }));
}


const populatePerkData = (liveStats : any, dataStore : any, gameId: string, platform: string) => {
    if (!liveStats.teamOne || !liveStats.teamTwo) return;
    if (dataStore.realtimeApi.events[`${platform}_${gameId}`].team100) {
        const perks = {};
        Object.values(liveStats.teamOne).forEach((player : any) => {
            const storedPlayer = dataStore.realtimeApi.events[`${platform}_${gameId}`].team100.players &&
                dataStore.realtimeApi.events[`${platform}_${gameId}`].team100.players.find(p => p.summonerName === player.summonerName);
            if (storedPlayer) {
                perks[playerPositions[storedPlayer.arrayIndex]] = {
                    data: player.perks,
                    keystoneID: player.keystoneID,
                };
            }
            storedPlayer.participantID = player.participantID;
            dataStore.realtimeApi.events[`${platform}_${gameId}`].team100.players =
                (dataStore.realtimeApi.events[`${platform}_${gameId}`].team100.players || []).map(p => {
                    if (p.summonerName === storedPlayer.summonerName) {
                        return storedPlayer;
                    }
                    return p;
                });
        });
        dataStore.realtimeApi.events[`${platform}_${gameId}`].team100.perks = perks;
    }

    if (dataStore.realtimeApi.events[`${platform}_${gameId}`].team200) {
        const perks = {};
        Object.values(liveStats.teamTwo).forEach((player : any) => {
            const storedPlayer = dataStore.realtimeApi.events[`${platform}_${gameId}`].team200.players &&
                dataStore.realtimeApi.events[`${platform}_${gameId}`].team200.players.find(p => p.summonerName === player.summonerName);
            if (storedPlayer) {
                perks[playerPositions[storedPlayer.arrayIndex]] = {
                    data: player.perks,
                    keystoneID: player.keystoneID,
                };
            }
            storedPlayer.participantID = player.participantID;
            dataStore.realtimeApi.events[`${platform}_${gameId}`].team200.players =
                (dataStore.realtimeApi.events[`${platform}_${gameId}`].team200.players || []).map(p => {
                    if (p.summonerName === storedPlayer.summonerName) {
                        return storedPlayer;
                    }
                    return p;
                });
        });
        dataStore.realtimeApi.events[`${platform}_${gameId}`].team200.perks = perks;
    }
}

const populateVisionData = (fujitsuDataEvent : any, dataStore : any, gameId: string, platform: string) => {
    dataStore.realtimeApi.events[`${platform}_${gameId}`].vision = fujitsuDataEvent.data;
    dataStore.realtimeApi.events[`${platform}_${gameId}`].vision.datetime = fujitsuDataEvent.date;
    // console.log('vision', dataStore.realtimeApi.events[`${this.state.platform}_${this.state.gameId}`].vision, fujitsuDataEvent);
}

const populateGameInfo = (liveStats : any, dataStore : any, gameId: string, platform: string) => {
    if (liveStats.participants) {
        const existingTeam100Players = dataStore.realtimeApi.events[`${platform}_${gameId}`].team100 &&
            dataStore.realtimeApi.events[`${platform}_${gameId}`].team100.players;
        const existingTeam200Players = dataStore.realtimeApi.events[`${platform}_${gameId}`].team200 &&
            dataStore.realtimeApi.events[`${platform}_${gameId}`].team200.players;
        liveStats.participants.forEach(participant => {
            if (existingTeam100Players) {
                existingTeam100Players.forEach(p => {
                    if (p.accountID === participant.accountID) {
                        p.participantID = participant.participantID;
                    }
                });
            }
            if (existingTeam200Players) {
                existingTeam200Players.forEach(p => {
                    if (p.accountID === participant.accountID) {
                        p.participantID = participant.participantID;
                    }
                });
            }
        });
    }
}

const updateTurretPlateStatus = (event : any, dataStore : LjlDataStore, platformGameId: string) => {
    const turretPlateStatus = get(dataStore, `realtimeApi.events.${platformGameId}.turretPlateStatus`);
    if(!turretPlateStatus) return;
    const team = turretPlateStatus[`team${(event.teamID === 100)? '200': '100'}`];
    team[event.lane] = team[event.lane] + 1;
}

const updatePlayersStatsCreepsKills = async (creep : string, playerAccountId : string, gameTime : number, dataStore : LjlDataStore, platformIdGameId : string) => {
    console.log('creep updatePlayersStatsCreepsKills', creep, playerAccountId, gameTime);
    await new Promise((resolve)=>{
        const stats = get(dataStore, `realtimeApi.events.${platformIdGameId}.stats`, undefined);     
        const gameTimeSeconds = Math.round(gameTime/1000);
        stats[playerAccountId].positions[gameTimeSeconds] = {
            ...get(stats, `${playerAccountId}.positions.${gameTimeSeconds}`,{}),
            creepKilled: get(stats, `${playerAccountId}.positions.${gameTimeSeconds}.creepKilled`,[]).concat(creep),
        }
        resolve();
    })
}

const updateItemMovement = async (itemEventType : string, playerAccountID : string, itemID : string, gameTime : number, dataStore : LjlDataStore, platformIdGameId : string) => {
    console.log("updating item list for event", itemEventType, 'for',playerAccountID, 'item', itemID, 'at', gameTime);
    await new Promise((resolve) => {
        const playerStats = get(dataStore, `realtimeApi.events.${platformIdGameId}.stats.${playerAccountID}`, undefined);
        // console.log('playerStats', playerStats);
        if(playerStats){
            const playerItemsHistory = get(playerStats, `itemsHistory`,[]).concat({
                transactionType: itemEventType,
                item: itemID,
                gameTime,
            });
            playerStats.itemsHistory = playerItemsHistory;
        }
        resolve();
    })
}

const getPlayerAccountIDFromParticipantID = (participantId : number, dataStore : LjlDataStore, platformIdGameId : string) => {
    const team = get(dataStore, `realtimeApi.events[${platformIdGameId}].${participantId > 5 ? 'team200' : 'team100'}`);
    if(!team) return null;
    return  get(team, `playerAccountIds[${(participantId > 5)? (participantId-6): (participantId-1)}]`);
}

const processStatsUpdate = (liveStats : any, dataStore : LjlDataStore, platformIdGameId : string) => {
    const gameTimeMs : number = parseInt(liveStats.gameTime) || 0;
    const currentStatus = get(dataStore, `realtimeApi.events.${platformIdGameId}.jungleProximityProcessingStatus`, null);
    if(!currentStatus && (gameTimeMs > 900000)){ // the stats need to be calculated from the 3rd minute to the 15th minute (180s - 900s)
        processJunglerProximityStats(dataStore, platformIdGameId);
    }
    const existingStats = get(dataStore, `realtimeApi.events.${platformIdGameId}.stats`, {});
    liveStats.participants.forEach(participant => {
        // console.log("participant", participant);
        const existing = existingStats[`${participant.accountID}`] || {
            totalDamageDealtToChampions:{},
            positions: {},
        };
        existing.totalGold = participant.totalGold;
        existing.currentGold = participant.currentGold;
        existing.accountID = `${participant.accountID}`;
        // Get the evolution of the total damage dealt
        existing.totalDamageDealtToChampions[gameTimeMs/1000] = parseInt(participant.stats[38].value);
        //  Get current total damage dealt to turret
        existing.totalDamageDealtToTurrets = parseInt(participant.stats[49].value);
        existing.positions[gameTimeMs/1000] = {
            ...get(existing, `positions[${gameTimeMs/1000}]`,{}),
            ...participant.position,
            alive: participant.alive,
        };

        // Get the XP score and Level
        existing.level = participant.level;
        existing.XP = participant.XP;
        // const goldRange = existing.goldRange || {};
        // const remainder = parseInt(liveStats.gameTime) % 20000;
        // const timeGroup = (parseInt(liveStats.gameTime) - remainder) / 1000;
        // if (!goldRange[timeGroup]) {
        //     goldRange[timeGroup] = participant.totalGold;
        //     existing.goldRange = goldRange;
        existingStats[`${participant.accountID}`] = existing;
        //     // console.log('gold', participant.accountID, goldRange);
        // }
    });
    set(dataStore, `realtimeApi.events.${platformIdGameId}.stats`, existingStats);

    const team100 = liveStats.teams.find(team => team.teamID === 100);
    const team200 = liveStats.teams.find(team => team.teamID === 200);
    if (team100 && team200) {
        const existingTeamStats = get(dataStore, `realtimeApi.events.${platformIdGameId}.teamStats`, {});
        const existing100 = existingTeamStats.team100 || {};
        const existing200 = existingTeamStats.team200 || {};

        const goldRange100 = existing100.goldRange || {};
        const goldRange200 = existing200.goldRange || {};

        const remainder = gameTimeMs % 20000;
        const timeGroup = (gameTimeMs - remainder) / 1000;
        if (!goldRange100[timeGroup]) {
            goldRange100[timeGroup] = team100.totalGold;
            existing100.goldRange = goldRange100;
        }
        if (!goldRange200[timeGroup]) {
            goldRange200[timeGroup] = team200.totalGold;
            existing200.goldRange = goldRange200;
        }

        set(dataStore,  `realtimeApi.events.${platformIdGameId}.teamStats`, {
            team100: existing100,
            team200: existing200,
        });
    }
}

const processJunglerProximityStats = async (dataStore: LjlDataStore, platformIdGameId : string) => {
    const stats = get(dataStore, `realtimeApi.events.${platformIdGameId}.stats`, undefined);        
    const jungler100Positions = stats[getPlayerAccountIdFromPos('team100', 'jungle', dataStore, platformIdGameId)].positions;
    const top100Positions = stats[getPlayerAccountIdFromPos('team100', 'top', dataStore, platformIdGameId)].positions;
    const mid100Positions = stats[getPlayerAccountIdFromPos('team100', 'mid', dataStore, platformIdGameId)].positions;
    const adc100Positions = stats[getPlayerAccountIdFromPos('team100', 'adc', dataStore, platformIdGameId)].positions;

    const jungler200Positions = stats[getPlayerAccountIdFromPos('team200', 'jungle', dataStore, platformIdGameId)].positions;
    const top200Positions = stats[getPlayerAccountIdFromPos('team200', 'top', dataStore, platformIdGameId)].positions;
    const mid200Positions = stats[getPlayerAccountIdFromPos('team200', 'mid', dataStore, platformIdGameId)].positions;
    const adc200Positions = stats[getPlayerAccountIdFromPos('team200', 'adc', dataStore, platformIdGameId)].positions;

    const team100Stats = getJunglerProximity(jungler100Positions, [top100Positions, mid100Positions, adc100Positions]);
    const team200Stats = getJunglerProximity(jungler200Positions, [top200Positions, mid200Positions, adc200Positions]);
    set(dataStore, `realtimeApi.events.${platformIdGameId}.junglerProximityData`, {
        team100 :{
            TOP: team100Stats[0],
            MID: team100Stats[1],
            BOT: team100Stats[2],
        },
        team200 : {
            TOP: team200Stats[0],
            MID: team200Stats[1],
            BOT: team200Stats[2],
        },
    });
    set(dataStore, `realtimeApi.events.${platformIdGameId}.jungleProximityProcessingStatus`, true);
    // firebaseRealtimeDbSetValue('control-gameScreen/junglerProximityReady', true);
}

const getPlayerAccountIdFromPos = (teamID : string, pos : string, dataStore : LjlDataStore, platformIdGameId : string) => {
    const team = get(dataStore, `realtimeApi.events.${platformIdGameId}.${teamID}`, undefined);
    return get(team, `playerAccountIds[${playerPositions.indexOf(pos)}]`);
}

const getJunglerProximity = (junglerPosition : any, otherPositions : any[]) : number[] =>{
    if(!junglerPosition || !otherPositions) return [];
    let result = new Array(otherPositions.length).fill(0);
    let counter = new Array(otherPositions.length).fill(0);  //number of positions counted
    for(let i = 180; i < 900; i++){ //the proximity value is always calculated based on the 3-15min time period
        // eslint-disable-next-line no-loop-func
        otherPositions.forEach((positions : any, index : number)=>{
            if(junglerPosition[i] && positions[i]){
                counter[index] = counter[index]+1;
                if(isInRadius(junglerPosition[i], positions[i], 2000)){
                    result[index] = result[index] +1;
                }
            }
        });
    }
    result = result.map((value, index) => Math.round((value/counter[index])*100));
    return result;
}

const isInRadius = (pos1 : any, pos2 : any, radius : number) : boolean => {
    if(!pos1 || !pos2 || !radius) return false;
    const xDelta = Math.abs(pos1.x - pos2.x);
    const zDelta = Math.abs(pos1.z - pos2.z);
    const distance = Math.sqrt(Math.pow(xDelta, 2) + Math.pow(zDelta, 2));
    return distance < radius;
}

export const buildMatchGamesIfNeeded = (round : number, match : number, dataStore : LjlDataStore, teamsDefs : any) => {
    if(!dataStore.schedule) return;
    const matchData = dataStore.schedule.rounds[round].matches[match];
    if(matchData.games !== undefined && matchData.games !== null) return;
    const gamesData = matchData.games || [];
    const bestOf = matchData.bestOf;
    const matchGamesArray = range(0, (bestOf));
    if (!matchData.games) matchData.games = [];
    matchGamesArray.map((game) => {
        if(matchData.team100 && matchData.team200){
            gamesData[game] = matchData.games[game] || buildMatchGame(matchData.team100, matchData.team200, teamsDefs);
        }else{
            gamesData[game] = matchData.games[game] || buildMatchGameManyTeams(matchData.teams);
        }
        return true;
    });
    dataStore.schedule.rounds[round].matches[match].games = gamesData;
}


const buildMatchGame = (team100 : string, team200 : string, teamsDefs: any) => {
    const game = {
        gameId: undefined,
        gameNumber: undefined,
        platformId: 'ESPORTSTMNT06',
        teams: {
            [team100]: buildMatchGameTeam(team100, '100', teamsDefs),
            [team200]: buildMatchGameTeam(team200, '200', teamsDefs),
        },
        winner: undefined,
    };
    return game;
}

const buildMatchGameManyTeams = (teams) => {

    const game = {
        gameId: undefined,
        gameNumber: undefined,
        platformId: 'ESPORTSTMNT06',
        teams: teams.reduce((acc, team) => {
            acc[team.tricode] = team;
            return acc;
        }, {}),
        winner: undefined,
    };
    return game;
}

const buildMatchGameTeam = (teamTricode : string, side : string, teamsDefs: any) => {
    if(!teamsDefs) return undefined;
    const teamPlayers = teamsDefs[teamTricode] && Object.keys(teamsDefs[teamTricode].roster).length ? teamsDefs[teamTricode].roster : {};
    const p = Object.keys(teamPlayers).map(pl => ({name: pl, position: teamPlayers[pl].position}));
    const roster = {'top': {championId: undefined}, 'jungle': {championId: undefined}, 'mid': {championId: undefined}, 'adc': {championId: undefined}, 'support': {championId: undefined} };
    p.map(player => {
        if (!roster[player.position] || !roster[player.position].name) {
            const obj = {playerId: player.name, name: player.name, championId: undefined, value: player.name};
            roster[player.position] = obj;
        };
        return undefined;
    });
    return ({
        side: side,
        bans: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
        },
        roster: roster,
    })
}

// const sendToDekkiFrameIfNeeded = liveStatsEvent => {
//     if (liveStatsEvent.date) {
//         if (this.getCurrentTimestamp() - (Date.parse(liveStatsEvent.date) + (this.state.liveStatusDelay * 1000)) > 5000) { // don't process stuff from over 5s outdated
//             console.log('skipping frame event', liveStatsEvent.sequenceIndex, ' outdated.');
//             return;
//         }
//     }

//     const prefix = `${liveStatsEvent.platformID}/${liveStatsEvent.gameID}`;
//     firebaseRealtimeDbSetData(`${prefix}/lastEventTime`, liveStatsEvent.date || (new Date()).toISOString());
//     let team100, team200, killerTeam, killerPlayer, frameTriggerTime;
//     if (liveStatsEvent.date) {
//         frameTriggerTime = Date.parse(liveStatsEvent.date);
//         frameTriggerTime += (this.state.liveStatusDelay * 1000);
//         if (frameTriggerTime > Date.now()) {
//             frameTriggerTime = Date.now();
//         }
//         frameTriggerTime = (new Date(frameTriggerTime)).toISOString();
//     }
//     switch (liveStatsEvent.action) {
//         case 'queued_dragon_info':
//             firebaseRealtimeDbSetData(`${prefix}/queuedDragonInfo/${liveStatsEvent.sequenceIndex}`, { // TRACKING PURPOSES ONLY, THIS CAN BE DISABLED.
//                 ...liveStatsEvent,
//                 datetime: liveStatsEvent.date || ((new Date()).toISOString()),
//             });
//             break;
//         case 'epic_monster_spawn':
//             // firebaseRealtimeDbSetData(`${prefix}/epicMonsterSpawn/${liveStatsEvent.sequenceIndex}`, {
//             //     monsterType: liveStatsEvent.monsterType,
//             //     dragonType: liveStatsEvent.dragonType, //  air, earth, elder, fire, water
//             //     datetime: liveStatsEvent.date || ((new Date()).toISOString()),
//             // });
//             break;
//         case 'turret_plate_destroyed':
//             console.log("turret plate destroyed", liveStatsEvent);
//             firebaseRealtimeDbSetData(`${prefix}/turretPlateDestroyed/${liveStatsEvent.sequenceIndex}`, { // TRACKING PURPOSES ONLY, THIS CAN BE DISABLED.
//                 ...liveStatsEvent,
//                 datetime: liveStatsEvent.date || ((new Date()).toISOString()),
//             });
//             break;
//         case 'epic_monster_kill':
//                 firebaseRealtimeDbSetData(`${prefix}/epicMonsterKill/${liveStatsEvent.sequenceIndex}`, { // TRACKING PURPOSES ONLY, THIS CAN BE DISABLED.
//                     monsterType: liveStatsEvent.monsterType,
//                     dragonType: liveStatsEvent.dragonType, //  air, earth, elder, fire, water
//                     datetime: liveStatsEvent.date || ((new Date()).toISOString()),
//                 });
//                 if (liveStatsEvent.monsterType === 'dragon') {
//                     console.log('sending dragon kill to frame', liveStatsEvent);
//                     const videos = {
//                         air: 'https://cdn.dekki.com/uploads/lolesports/broadcastFrame/drake_cloud.webm',
//                         earth: 'https://cdn.dekki.com/uploads/lolesports/broadcastFrame/drake_mountain.webm',
//                         elder: 'https://cdn.dekki.com/uploads/lolesports/broadcastFrame/dragon_elder.webm',
//                         fire: 'https://cdn.dekki.com/uploads/lolesports/broadcastFrame/drake_infernal.webm',
//                         water: 'https://cdn.dekki.com/uploads/lolesports/broadcastFrame/drake_ocean.webm',
//                     };

//                     const dragonState = {
//                         url: videos[liveStatsEvent.dragonType],
//                         lastTriggerTime: frameTriggerTime || ((new Date()).toISOString()),
//                         // lastTriggerTime: ((new Date()).toISOString()),
//                         loop: null,
//                         name: `Dragon ${dragonDisplayNameMap[liveStatsEvent.dragonType]}`,
//                     };

//                     if (this.teamsAreFlipped()) {
//                         firebaseRealtimeDbUpdateStream(liveStatsEvent.killerTeamID === 100 ? 12 : 11, dragonState);
//                     } else {
//                         firebaseRealtimeDbUpdateStream(liveStatsEvent.killerTeamID === 100 ? 11 : 12, dragonState);
//                     }
//                 } else if (liveStatsEvent.monsterType === 'baron') {
//                     const baronState = {
//                         url: 'https://cdn.dekki.com/uploads/lolesports/broadcastFrame/baron.webm',
//                         lastTriggerTime: frameTriggerTime || ((new Date()).toISOString()),
//                         // lastTriggerTime: ((new Date()).toISOString()),
//                         loop: null,
//                         name: 'Baron',
//                     };

//                     if (this.teamsAreFlipped()) {
//                         firebaseRealtimeDbUpdateStream(liveStatsEvent.killerTeamID === 100 ? 12 : 11, baronState);
//                     } else {
//                         firebaseRealtimeDbUpdateStream(liveStatsEvent.killerTeamID === 100 ? 11 : 12, baronState);
//                     }
//                 }
//                 break;
//         case 'champion_kill_special':
//             if (['firstBlood', 'multi'].includes(liveStatsEvent.killType)) {
//                 team100 = processedEvents[`${this.state.platform}_${this.state.gameId}`] && processedEvents[`${this.state.platform}_${this.state.gameId}`].team100;
//                 team200 = processedEvents[`${this.state.platform}_${this.state.gameId}`] && processedEvents[`${this.state.platform}_${this.state.gameId}`].team200;

//                 if (team100 && team100.players) {
//                     killerPlayer = team100.players.find(p => p.participantID === liveStatsEvent.killer);

//                     if (killerPlayer) {
//                         killerTeam = team100;
//                     }
//                 }
//                 if (!killerTeam && team200 && team200.players) {
//                     killerPlayer = team200.players.find(p => p.participantID === liveStatsEvent.killer);
//                     if (killerPlayer) {
//                         killerTeam = team200;
//                     }
//                 }

//                 // firebaseRealtimeDbSetData(`${prefix}/championKillSpecial/${liveStatsEvent.sequenceIndex}`, {
//                 //     killType: liveStatsEvent.killType,
//                 //     killerTeamTricode: killerTeam.tricode,
//                 //     killerPosition: killerPlayer && playerPositions[killerPlayer.arrayIndex],
//                 //     datetime: liveStatsEvent.date || ((new Date()).toISOString()),
//                 //     killStreakLength: liveStatsEvent.killStreakLength,
//                 // });
//                 let type;
//                 switch (liveStatsEvent.killType) {
//                     case 'firstBlood':
//                         type = 'firstBlood';
//                         break;
//                     case 'multi':
//                         switch (liveStatsEvent.killStreakLength) {
//                             case 5:
//                                 type = 'pentakill';
//                                 break;
//                             default:
//                                 break;
//                         }
//                         break;
//                     default:
//                         break;
//                 }

//                 if (type) {
//                     const videos = {
//                         pentakill: 'https://cdn.dekki.com/uploads/lolesports/broadcastFrame/pentakill.webm',
//                         firstBlood: 'https://cdn.dekki.com/uploads/lolesports/broadcastFrame/firstblood.webm',
//                     };
//                     const state = {
//                         url: videos[type],
//                         lastTriggerTime: frameTriggerTime || ((new Date()).toISOString()),
//                         // lastTriggerTime: ((new Date()).toISOString()),
//                         loop: null,
//                         name: type === 'pentakill' ? 'Pentakill' : 'First Blood',
//                     };

//                     if (this.teamsAreFlipped()) {
//                         firebaseRealtimeDbUpdateStream(killerTeam === team100 ? 12 : 11, state);
//                     } else {
//                         firebaseRealtimeDbUpdateStream(killerTeam === team100 ? 11 : 12, state);
//                     }
//                 }
//             }
//             break;
//         // case 'champion_kill': { // this is when a player dies
//         //             team100 = processedEvents[`${this.state.platform}_${this.state.gameId}`] && processedEvents[`${this.state.platform}_${this.state.gameId}`].team100;
//         //             team200 = processedEvents[`${this.state.platform}_${this.state.gameId}`] && processedEvents[`${this.state.platform}_${this.state.gameId}`].team200;

//         //             if (liveStatsEvent.killerTeamID === 100) {
//         //                 killerPlayer = team100 && team100.players && team100.players.find(p => p.participantID === liveStatsEvent.killer);
//         //                 victimPlayer = team200 && team200.players && team200.players.find(p => p.participantID === liveStatsEvent.victim);
//         //             } else {
//         //                 killerPlayer = team200 && team200.players && team200.players.find(p => p.participantID === liveStatsEvent.killer);
//         //                 victimPlayer = team100 && team100.players && team100.players.find(p => p.participantID === liveStatsEvent.victim);
//         //             }

//         //             const state = {
//         //                 deathVideoUrl: 'https://www.w3schools.com/tags/movie.mp4',
//         //                 // lastDeathTime: liveStatsEvent.date || ((new Date()).toISOString()),
//         //                 lastDeathTime: ((new Date()).toISOString()),
//         //             };
//         //             if (victimPlayer) {
//         //                 let screenNumber;
//         //                 if (this.teamsAreFlipped()) {
//         //                     if (liveStatsEvent.killerTeamID === 100) {
//         //                         screenNumber = victimPlayer.arrayIndex + 1;
//         //                     } else {
//         //                         screenNumber = 6 + victimPlayer.arrayIndex;
//         //                     }
//         //                 } else {
//         //                     if (liveStatsEvent.killerTeamID === 100) {
//         //                         screenNumber = 6 + victimPlayer.arrayIndex;
//         //                     } else {
//         //                         screenNumber = victimPlayer.arrayIndex + 1;
//         //                     }
//         //                 }
//         //                 console.log('champion victim', victimPlayer, screenNumber, state);
//         //                 firebaseRealtimeDbSetDataCgpc(`${screenNumber - 1}/deathVideoUrl`, state.deathVideoUrl);
//         //                 firebaseRealtimeDbSetDataCgpc(`${screenNumber - 1}/lastDeathTime`, state.lastDeathTime);
//         //             }
//         //         }
//         //     break;
//         default:
//             break;
//     }
// }


