import React from "react";
import { doRestCall } from '../../../utils/rest';

const vmixLayerForGameScreenOverlays = 4;

export default ({BaronTimer} : {BaronTimer: boolean}) => {
  const baronTimerCurrentState : {current: boolean}= React.useRef();

  if(baronTimerCurrentState.current !== BaronTimer){
    baronTimerCurrentState.current = BaronTimer;
    if(BaronTimer){
      console.log('triggering on barontimer');
      triggerCutInComponentOnMultiview(1);
    }else{
      console.log('triggering off barontimer');
      triggerCutOutComponentOnMultiview(1);
    }
  }

  return (
    <React.Fragment>
      <div style={{color: 'white'}}>
        <p>Keep this window open on the PC where VMIX is running.</p>
        <div>
          <h1>TESTING BUTTONS</h1>
          {renderTestDiv()}
        </div>
      </div>
    </React.Fragment>
  );
};

const renderTestDiv = () => {
    return (
        <div>
            <button onClick={() => triggerFadeInGameScreenOverlay()}>FadeIn GameScreenOverlay (Layer {vmixLayerForGameScreenOverlays})</button>
            <button onClick={() => triggerFadeOutGameScreenComponent()}>FadeOut GameScreenOverlay (Layer {vmixLayerForGameScreenOverlays})</button>
            <button onClick={() => triggerCutInComponentOnMultiview(1)}>Enable Multiview Component1</button>
            <button onClick={() => triggerCutInComponentOnMultiview(2)}>Enable Multiview Component2</button>
            <button onClick={() => triggerCutInComponentOnMultiview(3)}>Enable Multiview Component3</button>
            <button onClick={() => triggerCutOutComponentOnMultiview(1)}>Disable Multiview Component1</button>
            <button onClick={() => triggerCutOutComponentOnMultiview(2)}>Disable Multiview Component2</button>
            <button onClick={() => triggerCutOutComponentOnMultiview(3)}>Disable Multiview Component3</button>
            {/* <button onClick={() => triggerFadeInComponent("BaronTimer")}>FadeIn BaronTimer</button> */}
        </div>
    );
};

const triggerFadeInGameScreenOverlay = async () => {
    const data = await doRestCall({
        host: 'localhost',
        port: 8088,
        path: `/API/?Function=OverlayInput${vmixLayerForGameScreenOverlays}In&Input=GameScreenOverlay`,
        method: 'GET',
    }, undefined, true);
    return data;
};

const triggerFadeOutGameScreenComponent = async () => {
  const data = await doRestCall({
      host: 'localhost',
      port: 8088,
      path: `/API/?Function=OverlayInput${vmixLayerForGameScreenOverlays}Out`,
      method: 'GET',
  }, undefined, true);
  return data;
};

const triggerCutInComponentOnMultiview = async (componentIndex : number) => {
  const data = await doRestCall({
      host: 'localhost',
      port: 8088,
      path: `/API/?Function=MultiViewOverlayOn&Input=GameScreenOverlay&Value=${componentIndex}`,
      method: 'GET',
  }, undefined, true);
  return data;
};

const triggerCutOutComponentOnMultiview = async (componentIndex : number) => {
  const data = await doRestCall({
      host: 'localhost',
      port: 8088,
      path: `/API/?Function=MultiViewOverlayOff&Input=GameScreenOverlay&Value=${componentIndex}`,
      method: 'GET',
  }, undefined, true);
  return data;
};


