import {ScreenGroupData, Screen, ConstantNode, ProcessingNode, DataItem,} from './defs';
import { processNode, createNode } from "./nodeTypes";
import { createScreen } from "./screenTypes";
import { matchesClasstype, paramMappings } from "./paramTypes";
import shortID from '../utils/shortID';
import { dataSourceReferenceType } from './nodeTypes/constantRef';
import { isMatch, cloneDeep } from 'lodash';
import { getFirebaseToken, firebaseRealtimeDbUpdatePatchbayOutput } from '../utils/firebase';
import { getUserID } from '../auth0';

export class ScreenGroup {
    data: ScreenGroupData
    private dataItemIdMap: any
    private nodeIdMap: any
    private dataItemIdToContainingNodeMap: any
    private inputData: any
    private isGroupInputMemo: any
    private constantNodesMemo: any
    private screenCallbackFunctions: any
    private screensIdMap: any
    private currentActiveScreen: string // selected by user
    setConstantValue(constantNodeId: string, value: any) : boolean{
        const node : ConstantNode = this.constantNodesMemo[constantNodeId];
        if (!constantNodeTypeMatches(node, value, this.dataItemIdMap)){
            return false;
        }
        node.value = value;
        return true;
    }
    addScreen(name: string, type: string) : Screen{
        const screen = createScreen(name, type);
        if (screen.inputs) {
            screen.inputs.forEach(input => {
                this.dataItemIdMap[input.id] = input;
            });
        }
        this.screensIdMap[screen.id] = screen;
        this.data.screens.push(screen);
        return screen;
    }
    removeScreen(id: string) : boolean {
        const screen : Screen = this.screensIdMap[id];
        screen.inputs.forEach(input => {
            this.detachDataItem(input.id);
            delete this.dataItemIdMap[input.id];
        });
        delete this.screensIdMap[id];
        delete this.screenCallbackFunctions[id];
        this.data.screens = this.data.screens.filter(screen => screen.id !== id);
        return true;
    }
    addDataSourceInput(id: string, key: string, type: string) : DataItem{
        const item : DataItem = {
            id: id || shortID(),
            name: key,
            type,
        };
        this.isGroupInputMemo[item.id] = true;
        this.dataItemIdMap[item.id] = item;
        this.data.inputs.push(item);
        return item;
    }
    updateDataSourceInputKey(id: string, key: string){
        const item = this.getDataItemById(id);
        item.name = key;
        this.dataItemIdMap[item.id] = item;
    }
    updateDataSourceInputType(id: string, type: string){
        const item = this.getDataItemById(id);
        item.type = type;
        this.dataItemIdMap[item.id] = item;
    }
    /**
     * Returns array of deleted Node Ids
     * @param id 
     */
    removeDataSourceInput(id: string) : string[]{
        const result : string[] = [];
        delete this.dataItemIdMap[id];
        this.data.inputs = this.data.inputs.filter(input => input.id !== id);
        // remove references to this group input data.
        Object.keys(this.data.connections).forEach(key => {
            if(this.data.connections[key] === id){
                delete this.data.connections[key];
            }
        });
        Object.keys(this.constantNodesMemo).forEach(key => {
            const node : ConstantNode = this.constantNodesMemo[key];
            if(node.constantType === dataSourceReferenceType){
                if(node.value === id){
                    result.push(node.id);
                    this.deleteNode(node.id);
                }
            }
        });
        return result;
    }
    addNode(nodeType: string, posX: number, posY: number, initialValue? : any) : ProcessingNode{
        const node : ProcessingNode = createNode(nodeType);
        if (!node) {
            return null;
        }
        node.posX = posX;
        node.posY = posY;

        const dataItems : DataItem[] = [];
        if (node.inputs) {
            node.inputs.forEach(input => {
                dataItems.push(input);
                this.dataItemIdToContainingNodeMap[input.id] = node;
            });
        }
        if (node.outputs) {
            node.outputs.forEach(output => {
                dataItems.push(output);
                this.dataItemIdToContainingNodeMap[output.id] = node;
            });
        }
        
        dataItems.forEach(dataItem => {
            this.dataItemIdMap[dataItem.id] = dataItem;
        });

        if(node.isConstant){
            const cNode = node as ConstantNode;
            if(initialValue !== null && initialValue !== undefined){
                if (!constantNodeTypeMatches(cNode, initialValue, this.dataItemIdMap)){
                    console.log('initial value on node doesnt match node type');
                    return null;
                }
                cNode.value = initialValue;
            }
            this.constantNodesMemo[node.id] = cNode;
        }else{
            this.nodeIdMap[node.id] = node;
        }

        this.data.nodes.push(node);

        // console.log('add', node);
        return node;
    }
    deleteNode(nodeId: string) : boolean{
        if (this.constantNodesMemo[nodeId]){
            // delete any connections from this node.
            Object.keys(this.data.connections).forEach(key => {
                if(this.data.connections[key] === nodeId){
                    delete this.data.connections[key];
                }
            });
            // delete node
            delete this.constantNodesMemo[nodeId];
        } else{
            const node : ProcessingNode = this.nodeIdMap[nodeId];
            if(!node){
                return true;
            }
            // delete any connections from this node.
            const outputIds = {};
            node.outputs.forEach(output => {
                outputIds[output.id] = true;
            });
            Object.keys(this.data.connections).forEach(key => {
                if(outputIds[this.data.connections[key]]){
                    delete this.data.connections[key];
                }
            });
            // delete any connections to this node
            node.inputs.forEach(input => {
                this.detachDataItem(input.id);
            });
            // delete this.data.connections[nodeId];
            // delete node
            delete this.nodeIdMap[nodeId];
        }
        this.data.nodes = this.data.nodes.filter(node => node.id !== nodeId);
        return true;
    }
    async getDataItemValue(item: DataItem, memo: any, processedByLiveScreen: boolean) {
        const inputNodeId = this.data.connections[item.id];
        if (!inputNodeId){
            return null;
        }
        if (memo[inputNodeId]) {
            return memo[inputNodeId];
        }
        if (this.isGroupInputMemo[inputNodeId]) {
            const item: DataItem = this.dataItemIdMap[inputNodeId];
            let value = this.inputData[item.name];
            if(Object.keys(paramMappings).includes(item.type)){
                value = paramMappings[item.type].create(value);
            }
            memo[inputNodeId] = value;
            return value;
        }
        if (this.constantNodesMemo[inputNodeId]){
            const node : ConstantNode = this.constantNodesMemo[inputNodeId];
            if(node){
                if(node.constantType === dataSourceReferenceType){
                    if(memo[node.value]){
                        return memo[node.value];
                    }
                    if(this.isGroupInputMemo[node.value]){
                        const item: DataItem = this.dataItemIdMap[node.value];
                        let value = this.inputData[item.name];
                        if(Object.keys(paramMappings).includes(item.type)){
                            value = paramMappings[item.type].create(value);
                        }
                        memo[node.value] = value;
                        return value;
                    }
                    return node.value;
                }else{
                    return node.value;
                }
            }
            return null;
        }
        const node: ProcessingNode = this.getNodeByInputOrOutputId(inputNodeId);
        if(!node){
            console.log('missing node for input node id', inputNodeId, item.id);
        }
        const inputDataPromises = [];
        for (let i = 0; i < node.inputs.length; i++) {
            inputDataPromises.push(this.getDataItemValue(node.inputs[i], memo, processedByLiveScreen));
        }
        const inputData = [];
        for(let i=0;i < inputDataPromises.length;i++){
            inputData.push(await inputDataPromises[i]);
        }
        const resultsArr = await processNode(node, inputData, processedByLiveScreen);
        for (let i = 0; i < resultsArr.length; i++) {
            memo[node.outputs[i].id] = resultsArr[i];
        }
        return memo[inputNodeId];
    }
    setInputData(key: string, value: any) {
        this.inputData[key] = value;
    }
    getInputData(key: string) {
        return this.inputData[key];
    }
    setCurrentActiveScreen(screenId: string) {
        this.currentActiveScreen = screenId;
    }
    getCurrentActiveScreenId() : string{
        return this.currentActiveScreen;
    }
    getCurrentActiveScreen() : Screen{
        return this.screensIdMap[this.currentActiveScreen];
    }
    async getDataForScreen(screenId: string, processedByLiveScreen: boolean) : Promise<any[]>{
        const dataArr: any[] = [];
        const screen: Screen = this.screensIdMap[screenId];
        const memo = {};
        if (screen) {
            const promises = [];
            for(let i=0;i < screen.inputs.length;i++){
                const input = screen.inputs[i];
                promises.push(this.getDataItemValue(input, memo, processedByLiveScreen));
            }
            for(let i=0;i < promises.length;i++){
                dataArr.push(await promises[i]);
            }
        }
        if(this.screenCallbackFunctions[screenId]){
            this.screenCallbackFunctions(dataArr);
        }
        return dataArr;
    }
    async getDataForNode(nodeId: string, processedByLiveScreen: boolean) : Promise<any>{
        let node = this.getNodeById(nodeId);
        if(!node){
            node = this.getConstantNodeById(nodeId);
            if(!node){
                return {};
            }
            if((node as ConstantNode).constantType === dataSourceReferenceType){
                return {
                    outputs: this.inputData[(node as ConstantNode).value],
                }
            }
            return {
                outputs: (node as ConstantNode).value,
            }
        }
        const inputsPromises = [];
        const memo = {};
        for(let i=0;i < node.inputs.length;i++){
            const input = node.inputs[i];
            inputsPromises.push(this.getDataItemValue(input, memo,processedByLiveScreen));
        }
        const inputs = [];
        for(let i=0;i < inputsPromises.length;i++){
            inputs.push(await inputsPromises[i]);
        }
        const outputs = await processNode(node, inputs, processedByLiveScreen);
        return {
            inputs,
            outputs,
        };
    }
    async getDataForCurrentActiveScreen(processedByLiveScreen: boolean) : Promise<any[]>{
        return await this.getDataForScreen(this.currentActiveScreen, processedByLiveScreen);
    }
    getDataItemById(dataItemId: string) : DataItem{
        return this.dataItemIdMap[dataItemId];
    }
    getConstantNodeById(constantNodeId: string) : ConstantNode {
        return this.constantNodesMemo[constantNodeId];
    }
    attachDataItems(fromId: string, toId: string): boolean {
        const toItem: DataItem = this.dataItemIdMap[toId];
        if(!toItem) return false;
        if (this.constantNodesMemo[fromId]){
            const cNode = this.constantNodesMemo[fromId] as ConstantNode;
            let typeToCompare = cNode.constantType;
            if (cNode.constantType === dataSourceReferenceType && this.dataItemIdMap[cNode.value]){
                typeToCompare = this.dataItemIdMap[cNode.value].type;
            }
            if(toItem.type !== 'any'){
                if(toItem.type === 'any[]'){
                    if(!typeToCompare.includes('[]')){
                        return false;
                    }
                }else{
                    if (typeToCompare !== 'any' && typeToCompare !== toItem.type) {
                        return false;
                    }
                }
            }
        } else{
            const fromItem: DataItem = this.dataItemIdMap[fromId];
            let typeToCompareFrom = fromItem.type;
            if(typeToCompareFrom === 'any[]'){
                if(!toItem.type.includes('[]')){
                    return false;
                }
            }else{
                if(toItem.type === 'any[]'){
                    if(!typeToCompareFrom.includes('[]')){
                        return false;
                    }
                }else{
                    if (typeToCompareFrom !=='any' && toItem.type !== 'any' && typeToCompareFrom !== toItem.type) {
                        return false;
                    }
                }
            }
        }
        this.data.connections[toId] = fromId;
        return true;
    }
    detachDataItem(toId: string): boolean {
        delete this.data.connections[toId];
        return true;
    }
    /**
     *  Get Non constant node
     * @param inputOrOutputId
     */
    getNodeByInputOrOutputId(inputOrOutputId: string): ProcessingNode {
        return this.dataItemIdToContainingNodeMap[inputOrOutputId];
    }
    /**
     *  Get Non constant node
     * @param nodeId
     */
    getNodeById(nodeId: string): ProcessingNode{
        return this.nodeIdMap[nodeId];
    }
    getJson(pretty? : boolean): string {
        return pretty ? JSON.stringify(this.data || {},null,2) : JSON.stringify(this.data || {});
    }
    moveNode(nodeId: string, x: number, y: number) : boolean{
        const node : ProcessingNode = this.nodeIdMap[nodeId] || this.constantNodesMemo[nodeId];
        node.posX = x;
        node.posY = y;
        return true;
    }
    /**
     * The callback function is passed the data being input to the screen.
     * @param screenId 
     * @param callbackFunc 
     */
    addScreenUpdatedCallback(screenId: string, callbackFunc : (data : any) => void){
        this.screenCallbackFunctions[screenId] = callbackFunc;
    }
    constructor(jsonData: string) {
        this.dataItemIdMap = {};
        this.inputData = {};
        this.isGroupInputMemo = {};
        this.screensIdMap = {};
        this.dataItemIdToContainingNodeMap = {};
        this.nodeIdMap = {};
        this.constantNodesMemo = {};
        this.screenCallbackFunctions = {};
        this.data = new ScreenGroupData(JSON.parse(jsonData));
        const dataItems: DataItem[] = [];
        if (this.data.inputs) {
            this.data.inputs.forEach(input => {
                dataItems.push(input);
                this.isGroupInputMemo[input.id] = true;
            });
        }
        if (this.data.screens) {
            this.data.screens.forEach(screen => {
                if (screen.inputs) {
                    screen.inputs.forEach(input => {
                        dataItems.push(input);
                    });
                }
                this.screensIdMap[screen.id] = screen;
            });
        }
        if (this.data.nodes) {
            this.data.nodes.forEach(node => {
                if (node.inputs) {
                    node.inputs.forEach(input => {
                        dataItems.push(input);
                        this.dataItemIdToContainingNodeMap[input.id] = node;
                    });
                }
                if (node.outputs) {
                    node.outputs.forEach(output => {
                        dataItems.push(output);
                        this.dataItemIdToContainingNodeMap[output.id] = node;
                    });
                }
                if(node.isConstant){
                    this.constantNodesMemo[node.id] = (node as ConstantNode);
                }else{
                    this.nodeIdMap[node.id] = node;
                }
            });
        }
        dataItems.forEach(dataItem => {
            this.dataItemIdMap[dataItem.id] = dataItem;
        });
        this.processChangesToFirebase();
    }
    private lastFirebaseData : any = null;
    processChangesToFirebase(){
        setTimeout(async () => {
            // console.log("checking for changes");
            if(this.currentActiveScreen !== null){
                const dataToSync = await this.getDataForCurrentActiveScreen(true);
                if(!isMatch(this.lastFirebaseData, dataToSync)){
                    // console.log('data changed');
                    this.lastFirebaseData = cloneDeep(dataToSync);

                    const userID = await getUserID();
                    if(!userID){
                        try{
                            window.alert('You must login!');
                        }catch(error){}
                        return;
                    }
                    const firebaseToken = await getFirebaseToken();
                    if (!firebaseToken) {
                        try{
                            window.alert('You must login!');
                        }catch(error){}
                        return;
                    }
                    console.log('updating firebase');
                    const screen : Screen = this.screensIdMap[this.currentActiveScreen];
                    await firebaseRealtimeDbUpdatePatchbayOutput(firebaseToken, userID, this.data.id, {
                        type: screen && screen.type,
                        data: dataToSync.reduce((acc, item, index) => {
                            if(!screen){
                                return acc;
                            }
                            acc[screen.inputs[index].name] = item;
                            return acc;
                        }, {}),
                        lastUpdated:  (new Date()).toISOString(),
                    });
                }
            }
            this.processChangesToFirebase();
        }, 50);
    }
}

const constantNodeTypeMatches = (node : ConstantNode, value: any, dataItemIdMap : any) : boolean => {
    let type = node.constantType;
    if(type === dataSourceReferenceType){
        type = 'string';
        if(!dataItemIdMap[value]){
            return false;
        }
    }
    if (type === 'any') {
        type = 'string';
    }
    if (typeof (value) !== type && !matchesClasstype(type, value)) {
        return false;
    }
    return true;
}

