import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "jsonToObject",
        inputs: [{
            id: shortID(),
            name: "json",
            type: "string"
        }],
        outputs: [{
            id: shortID(),
            name: "object",
            type: "any"
        }],
    }),
    process: (input: any[], nodeId : string, processedByLiveScreen : boolean) => {
        if(!input) return [null];
        if(input[0] === null || input[0] === undefined){
            return [null];
        }
        try {
            return [JSON.parse(input[0])];
        } catch (error) {
            return [null];
        }
    },
}
