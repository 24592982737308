import shortID from '../../../utils/shortID';
import { ConstantNode } from '../../defs';
import { TestSchedule } from '../../paramTypes';

export default {
    create: () => new ConstantNode({
        id: shortID(),
        constantType: 'TestSchedule',
        value: new TestSchedule({
            matches: [],
        }),
    }),
}