import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';
import { doRestCall } from '../../../utils/rest';

const cache = {

};

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "file",
        inputs: [{
            id: shortID(),
            name: "url",
            type: "string"
        },{
            id: shortID(),
            name: "cache",
            type: "boolean",
        }],
        outputs: [{
            id: shortID(),
            name: "data",
            type: "any"
        }],
    }),
    process: async (input: any[], nodeId : string, processedByLiveScreen : boolean) => {
        if (!input || input.length < 1) return [null];
        try{
            const url = input[0];
            if(!url) return [null];

            if(input[1]){
                // do cache
                const cached = cache[nodeId];
                if(cached){
                    if(Date.now() < cached.expiration){
                        return cached.data;
                    }
                }
            }

            let parsed;
            if(url.startsWith('http')){
                parsed = url.replace(/http:\/\//, '');
                parsed = parsed.replace(/https:\/\//, '');
            }else{
                parsed = url;
            }
            const splitted = parsed.split('/');
            const hostname = splitted[0];
            const path = `/${splitted.slice(1).join('/')}`;
            const data = await doRestCall({
                hostname,
                // port: 441,
                path,
                method: 'GET',
            });
            // console.log(data);

            if(input[1]){
                cache[nodeId] = {
                    data,
                    expiration: Date.now() + 60000, // 1 minute cache
                };
            }

            return [data];
        }catch(error){
            console.log('error', error);
            return [null];
        }
    },
}
