import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';

/**
 * Expected Use:
 * Input: array of 1 element containing a string.
 * Output: array of 1 element containing string converted to lowercase.
 */
export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "toLowerCase",
        inputs: [{
            id: shortID(),
            name: "in",
            type: "string"
        }],
        outputs: [{
            id: shortID(),
            name: "out",
            type: "string"
        }],
    }),
    process: (input: any[], nodeId : string, processedByLiveScreen : boolean) => {
        if (!input || input.length === 0) return null;
        return [input[0] && input[0].toLowerCase()];
    },
}