import shortID from '../../../utils/shortID';
import { ConstantNode } from '../../defs';

export const dataSourceReferenceType = 'constantRef';
export default {
    create: () => new ConstantNode({
        id: shortID(),
        constantType: dataSourceReferenceType,
        value: '',
    }),
}