import React from 'react';
import Rete from "rete";
import { ProcessingNode, DataItem } from '../../nodeSystem/defs';
import { ScreenGroup } from '../../nodeSystem/ScreenGroup';
import { genericSocket } from './socketTypes';
import { UiNode } from '../UiNode';

 export class UiNodeComponent extends Rete.Component {
    group : ScreenGroup;
    type : string
    submenu : string[]
    constructor(name: string, group : ScreenGroup, type : string, submenu : string[] = null) {
      super(name);
      this.group = group;
      this.type = type;
      this.submenu = submenu;
    }
  
    builder(node) {
      // console.log('node ui data', node);
      var response = node;
      let backendNode;
      if(node.data.node){
        backendNode = node.data.node;
      }else{
        backendNode = this.group.addNode(this.type,0,0);
      }
      const inputs = backendNode.inputs;
      const outputs = backendNode.outputs;

      if(inputs && inputs.length > 0){
        inputs.forEach((input : DataItem) => {
          response = response.addInput(new Rete.Input(input.id, `${input.name}(${input.type})`, genericSocket));
        });
      }
      if(outputs && outputs.length > 0){
        outputs.forEach((output : DataItem) => {
          response = response.addOutput(new Rete.Output(output.id, `${output.name}(${output.type})`, genericSocket));
        });
      }
  
      
      console.log('new node', backendNode);
      node.data = {
        screenGroup: this.group,
        node : backendNode,
      };
      return response;
    }
  
    worker(node, inputs, outputs) {
      // outputs["num"] = node.data.num;
    }
  }

  export class FileComponent extends UiNodeComponent {
    constructor(group : ScreenGroup) {
      super("File", group, 'file');
    }
  }

  export class SelectorComponent extends UiNodeComponent {
    constructor(group : ScreenGroup) {
      super("Array Selector", group, 'selector');
    }
  }

  export class ObjectPathComponent extends UiNodeComponent {
    constructor(group : ScreenGroup) {
      super("Object Path", group, 'objectPath');
    }
  }

  export class JoinToArrayComponent extends UiNodeComponent {
    constructor(group : ScreenGroup) {
      super("Join into Array", group, 'joinToArray');
    }
}
  
export class JsonToObjectComponent extends UiNodeComponent {
  constructor(group : ScreenGroup) {
    super("JSON to Object", group, 'jsonToObject');
  }
}

  export class EventFilterComponent extends UiNodeComponent {
    constructor(group : ScreenGroup) {
      super("Event Filter", group, 'eventFilter');
    }
  }

  export class LogicalOrComponent extends UiNodeComponent {
    constructor(group : ScreenGroup) {
      super("Logical OR", group, 'logicalOr');
    }
  }

  export class RiotRealtimeApiComponent extends UiNodeComponent {
    constructor(group : ScreenGroup) {
      super("Riot Realtime API", group, 'riotRealtimeApi', ['LJL']);
    }
  }

  export class LjlJsonTeamProcessorComponent extends UiNodeComponent {
    constructor(group : ScreenGroup) {
      super("LJL Team Parser", group, 'ljlJsonTeamProcessor', ['LJL']);
    }
}
  
export class LjlJsonScheduleProcessorComponent extends UiNodeComponent {
  constructor(group : ScreenGroup) {
    super("LJL Schedule Parser", group, 'ljlJsonScheduleProcessor', ['LJL']);
  }
}

export class LjlStandingsComponent extends UiNodeComponent {
  constructor(group : ScreenGroup) {
    super("LJL Standings", group, 'ljlStandings', ['LJL']);
  }
}

  export class LolChampionMapperComponent extends UiNodeComponent {
    constructor(group : ScreenGroup) {
      super("LOL Champion Mapper", group, 'lolChampionMapper', ['LJL']);
    }
  }

  export class LolGameScreenComponent extends UiNodeComponent {
    constructor(group : ScreenGroup) {
      super("LOL GameScreen Mapper", group, 'lolGameScreen', ['LJL']);
    }
  }

  export class SwapInputsComponent extends UiNodeComponent {
    constructor(group : ScreenGroup) {
      super("Swap Inputs", group, 'swapInputs');
    }
  }

  export class MockApiComponent extends UiNodeComponent {
    constructor(group : ScreenGroup) {
      super("Mock Api", group, 'mockApi', ['LJL', 'Mock']);
    }
  }

