import Auth0, {auth0Promise} from '../auth0';
// import storage from './storage';

const dekkiEndpoint = process.env.REACT_APP_GRAPHQL || 'http://localhost:3001/api';
const dekkiQueryEndpoint = process.env.REACT_APP_QUERYAPI || 'http://localhost:3001/api/query';
const dataProcessorEndpoint = process.env.REACT_APP_DATA_PROCESSOR_GRAPHQL || 'http://localhost:3991/api/graph';
const dataProcessorQueryEndpoint = process.env.REACT_APP_DATA_PROCESSOR_GRAPHQL || 'http://localhost:3991/api/query';

export const toEnum = str => str ? str.toUpperCase().split('-').join('_') : undefined;
export const fromEnum = str => str ? str.toLowerCase().split('_').join('-') : undefined;

export const getFirebaseTokenFromApi = () => GraphQL(`{
    firebase
}`).then(data => data && data.data && data.data.firebase);

export const getPatchbaysForLoggedInUser = () => GraphQL(`query{patchwork {
    getForLoggedInUser{
        results{
            id
            name
            userID
            adminUserIDs
            data
        }
    }
  }}`, null, null, null, dataProcessorEndpoint).then(data => data.data && data.data.patchwork && data.data.patchwork.getForLoggedInUser && data.data.patchwork.getForLoggedInUser.results);

  export const savePatchbay = (id, name, data) => GraphQL(`mutation {
    patchwork {
      save(patchwork: {
        id: ${JSON.stringify(id)}
        name: ${JSON.stringify(name)}
        data: ${JSON.stringify(data)}
      }){
        id
      }
    }
  }`, null, null, null, dataProcessorEndpoint).then(data => data.data && data.data.patchwork && data.data.patchwork.save && data.data.patchwork.save.id);

  export const deletePatchbay = (id) => GraphQL(`mutation {
    patchwork {
      delete(id: "${id}")
    }
  }`, null, null, null, dataProcessorEndpoint);


const handleFault = ({message}) => {
    // this probably only happens when there's a 500
    console.error('GraphQL', message);
    if (message) {
        switch (message) {
            case 'spam-detected':
               // dialog(ERROR(message));
                break;
            case 'email-not-verified':
            //    dialog(ERROR_UNVERIFIED_EMAIL());
                break;
            default:
                if (message.indexOf && message.indexOf('ailed to fetch') > -1) return {};
                break;
        }
    }
    return {error: message};
};

const GraphQL = async (request, apiResource = undefined, doGet = undefined, auth = undefined, endpoint = undefined) => {
    if (!auth0Promise) {
        new Auth0();
        await auth0Promise;
    }
    let useEndpoint = endpoint || dekkiEndpoint;
    const contentType = {};
    const body = {};
    if (doGet) {
        useEndpoint = `${process.env.REACT_APP_QUERYAPI}?${request}`;
    } else {
        contentType['content-type'] = 'application/json';
        body['body'] = JSON.stringify({'query': request});
    }
    const call = auth => fetch(apiResource ? process.env[`REACT_APP_GRAPHQL_${apiResource.toUpperCase()}`] || useEndpoint : useEndpoint, {
        ...body,
        method: doGet ? 'GET' : 'POST',
        headers: {
            ...contentType,
            ...auth,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.errors && json.errors[0].message !== 'forbidden') {
                throw new Error(json.errors[0].message);
            }
            else return json;
        })
        .catch(error => handleFault(error));
    if (auth) {
        return call(auth);
    }
    const authHeader = (await auth0Promise).getAuthHeader();
    return call(authHeader);
};

export default GraphQL
