import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "joinToArray",
        inputs: [{
            id: shortID(),
            name: "item1",
            type: "any"
        }, {
            id: shortID(),
            name: "item2",
            type: "any"
        }, {
            id: shortID(),
            name: "item3",
            type: "any"
        }, {
            id: shortID(),
            name: "item4",
            type: "any"
        }, {
            id: shortID(),
            name: "item5",
            type: "any"
        }],
        outputs: [{
            id: shortID(),
            name: "outputArray",
            type: "any[]"
        }],
    }),
    process: (input: any[], nodeId : string, processedByLiveScreen : boolean) => {
        if (!input || input.length < 1) return [];
        return [input.filter(item => item)];
    },
}
