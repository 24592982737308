import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';


let count = 0;

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "mockApi",
        inputs: [],
        outputs: [{
            id: shortID(),
            name: "outputArray",
            type: "any[]"
        }],
    }),
    process: (input: any[], nodeId : string, processedByLiveScreen : boolean) => {
        let data;
        const mockEvents = [
            {
                eventId: 'event1',
                data: {
                    test: 'test value',
                    food: 'burger',
                    sonic: 'fast',
                    mario: 'slow',
                },
            },
            {
                eventId: 'event2',
                data: {
                    test: 'test 2nd value',
                    food: 'fries',
                    sonic: 'faster',
                    mario: 'slower',
                },
            },
            {
                eventId: 'event3',
                data: {
                    test: 'test 3rd value',
                    food: 'soda',
                    sonic: 'fastest',
                    mario: 'slowest',
                },
            },
            {
                eventId: 'event4',
                data: {
                    test: 'test 4th value',
                    food: 'tabemono',
                    sonic: 'super',
                    mario: 'not super',
                },
            },
            {
                eventId: 'event5',
                data: {
                    test: 'test 5th value',
                    food: 'eat',
                    sonic: 'win',
                    mario: 'lose',
                },
            },
            {
                eventId: 'event6',
                data: {
                    test: 'test 6th value',
                    food: 'drink',
                    sonic: 'wut',
                    mario: 'lol',
                },
            },
            {
                eventId: 'event7',
                data: {
                    test: 'test 7th value',
                    food: '77777',
                    sonic: '7777',
                    mario: '7777',
                },
            },
        ];
        try{
            switch(count){
                case 0: 
                    data = mockEvents.slice(0, 2);
                    break;
                case 1:
                    data = mockEvents.slice(0, 5);
                    break;
                default:
                    data = mockEvents;
                    break;
            }
            count++;

            return [data];
        }catch(error){
            console.log('error', error);
            return [null];
        }
    },
}
