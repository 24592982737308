import { delay } from 'lodash';
import env from './env';
import moment from 'moment';
import storage from '../utils/storage';
import { getQueryParam } from '../utils/uri';
import GraphQL from '../utils/graphql';
import i18n from '../i18n';
import ja from './ja';
import { getLanguage, getLocale } from '../utils/language';
import auth0 from 'auth0-js';

const HEARTBEAT_TIMEOUT = 5 * 60 * 1000;
const AUTH_TIMEOUT = 30 * 1000;

// const LOCALE = 'en-US';

export const isLoggedIn = async () => {
    if (auth0Promise !== null) {
        new Auth0();
    }
    await auth0Promise;
    if (getUserInfoPromise !== null) {
        await getUserInfoPromise;
        if (profile && profile.auth) {
            return true;
        }
    }
    return false;
};

export const getAuthHeader = async () => {
    if (await isLoggedIn()) {
        return auth0Promise.getAuthHeader();
    }
    return {};
}

export const getUserID = async () => {
    if (await isLoggedIn()) {
        if (profile) {
            return profile.id;
        }
        return null;
    }
    return null;
}

export const getUserInfo = async () => {
    if (await isLoggedIn()) {
        if (profile) {
            return profile;
        }
        return null;
    }
    return null;
}

export const showLogin = async () => {
    if (auth0Promise) {
        (await auth0Promise).login();
      } else {
        new Auth0().login();
      }
}

export const logout = async () => {
    if (auth0Promise) {
        (await auth0Promise).logout();
      } else {
        new Auth0().logout();
      }
}



const heartbeat = () => GraphQL(`query {
    heartbeat
  }`);

export let profile : any = {};

export let auth0Promise, getUserInfoPromise = null;

export default class Auth0 {
    afterAuthCallback = undefined;
    webAuth : auth0.WebAuth;

    constructor(afterAuthCallback = undefined) {
        this.afterAuthCallback = afterAuthCallback;
        if (!auth0Promise) {
            auth0Promise = new Promise(resolve => this.connect(resolve));
        }
    }

    connect = async resolve => {
        this.webAuth = new auth0.WebAuth({
            domain: 'login.dekki.com',
            clientID: env.client,
        });
    
        const authToken = storage.get('broadcast-access-token');
        if (authToken) {
            if (!getUserInfoPromise) {
                await this.getUserInfo(authToken);
            }
        } else {
            this.logout();
        }
        resolve(this);
    };

    onAuth = async () => {
        // console.log('auth0: onAuth called', getQueryParam('id_token'), getQueryParam('access_token'), getQueryParam('state'));
        const idToken = getQueryParam('id_token');
        const accessToken = getQueryParam('access_token');
        storage.set('broadcast-access-token', accessToken);
        storage.set('broadcast-id-token', idToken);
        await this.getUserInfo(accessToken);
        if (this.afterAuthCallback) {
            this.afterAuthCallback();
        }
        window.location.href = decodeURIComponent(window.atob(getQueryParam('state')));
    };

    setLocalProfile(lprofile) {
        storage.set('broadcast-u-p', JSON.stringify(lprofile));
        profile = lprofile;
    }

    getUserInfo = async accessToken => {
        // console.log('getting user info');
        if (!getUserInfoPromise) {
            getUserInfoPromise = new Promise(async resolve => {
                const lastAuth = parseInt(storage.get('broadcast-last-auth'));
                if (isNaN(lastAuth) || Date.now() - lastAuth > AUTH_TIMEOUT) {
                    this.webAuth.client.userInfo(accessToken, async (error, result) => {
                        if (error) {
                            this.logout();
                            console.warn('Auth Error', error);
                        } else {
                            storage.set('broadcast-last-auth', Date.now());
                            this.setLocalProfile({
                                id: result.user_id.split('|').pop(),
                                displayName: result.user_metadata.displayName,
                                email: result.email,
                                emailVerified: result.email_verified,
                                emailOptIn: result.user_metadata.emailOptIn,
                                emailMyPostFirst: result.user_metadata.emailMyPostFirst,
                                emailMyPostNewComment: result.user_metadata.emailMyPostNewComment,
                                emailMyPostFirstLike: result.user_metadata.emailMyPostFirstLike,
                                emailMyPost10Likes: result.user_metadata.emailMyPost10Likes,
                                emailMyPost100Likes: result.user_metadata.emailMyPost100Likes,
                                emailMyCommentNewComment: result.user_metadata.emailMyCommentNewComment,
                                emailNewFollower: result.user_metadata.emailNewFollower,
                                emailFollowedUserNewPost: result.user_metadata.emailFollowedUserNewPost,
                                emailFollowedUserNewComment: result.user_metadata.emailFollowedUserNewComment,
                                emailFollowedUser100Likes: result.user_metadata.emailFollowedUser100Likes,
                                emailFollowedSubjectPopularPost: result.user_metadata.emailFollowedSubjectPopularPost,
                                emailFollowedSubjectNewTournament: result.user_metadata.emailFollowedSubjectNewTournament,
                                emailFollowedPostNewComment: result.user_metadata.emailFollowedPostNewComment,
                                emailFollowedCommentNewComment: result.user_metadata.emailFollowedCommentNewComment,
                                emailLanguage: result.user_metadata.emailLanguage,
                                followedContentLanguages: result.user_metadata.followedContentLanguages,
                                joined: moment(result.createdAt).format('LL'),
                                auth: this.getAuthHeader(),
                            });
                            // this.checkHeartbeat();
                        }
                        resolve();
                    });
                } else {
                    let userProfile = storage.get('broadcast-u-p');
                    if (userProfile) {
                        userProfile = JSON.parse(userProfile);
                        profile = {
                            ...profile,
                            ...userProfile,
                            auth: this.getAuthHeader(),
                        }
                    } else {
                        // This shouldn't happen but just in case
                        this.logout();
                    }
                    resolve();
                }
            });
        }
    };

    lastHB = undefined;

    // heartbeat is to update the "last seen" time
    checkHeartbeat = () => {
        const last = storage.get('broadcast-hb') || this.lastHB;
        const now = new Date().getTime();
        if (!last || now - last > HEARTBEAT_TIMEOUT) {
            storage.set('broadcast-hb', now);
            this.lastHB = now;
            heartbeat();
            this.checkHeartbeat();
        } else delay(() => {
            this.checkHeartbeat();
        }, HEARTBEAT_TIMEOUT - (now - last) + 5);
    };

    onError = error => {
        this.logout();
        if (process.env.NODE_ENV !== 'production') {
            console.warn('LOCK onError', JSON.stringify(error));
        } else {
            throw new Error(`LOCK onError: ${JSON.stringify(error)}`);
        }
    };

    login = () => {
        this.webAuth.authorize({
            redirectUri: window.location.origin,
            responseType: 'token id_token',
            scope: 'openid email',
            state: window.btoa(encodeURIComponent(storage.get('pal') || window.location.href.substr(window.location.origin.length))),
        });
    };

    isAuthenticated = () => !!this.getIdToken();

    getAccessToken = () => storage.get('broadcast-access-token');
    getIdToken = () => storage.get('broadcast-id-token');

    getAuthHeader = () => this.isAuthenticated() ? { 'Authorization': `Bearer ${this.getIdToken()}` } : {};

    logout = () => {
        // console.log('logout');
        storage.remove('broadcast-access-token');
        storage.remove('broadcast-id-token');
        storage.remove('broadcast-last-auth');
        storage.remove('broadcast-u-p');
        storage.remove('broadcast-firebaseToken');
        getUserInfoPromise = null;
    };

    getOptions = () => {
        let languageDictionary : any;
        const retrievedLang = getLanguage();
        const language = retrievedLang === 'zh' ? 'zh-tw' : undefined;
        if (!language) {
            languageDictionary = retrievedLang === 'ja' ? ja : {};
            languageDictionary.title = i18n('auth.login');
            languageDictionary.signUpTerms = i18n('auth.agree');
        }

        // console.log('language', navigator.language);
        return {
            auth: {
                responseType: 'token',
                params: {
                    scope: 'openid email',
                    state: window.btoa(window.location.href),
                },
                redirectUrl: `${window.location.origin}`,
            },
            avatar: null,
            usernameStyle: 'email',
            allowedConnections: ['Username-Password-Authentication'],
            socialButtonStyle: 'small',
            configurationBaseUrl: 'https://cdn.auth0.com',
            overrides: {
                __tenant: 'login.dekki.com',
                '__token_issuer': 'playbrain.auth0.com',
            },
            mustAcceptTerms: true,
            rememberLastLogin: false,
            theme: {
                logo: `${process.env.REACT_APP_ASSETS}images/150x150_dekki-logo-small.png`,
                primaryColor: '#ff9100',
            },
            language,
            languageDictionary,
            additionalSignUpFields: [{
                name: 'displayName',
                placeholder: i18n('auth.display-name'),
            },
            {
                type: 'select',
                name: 'emailLanguage',
                placeholder: 'Language',
                options: [
                    { value: 'en-US', label: i18n('i18n.en-US') },
                    { value: 'ja-JP', label: i18n('i18n.ja-JP') },
                    { value: 'ko-KR', label: i18n('i18n.ko-KR') },
                    { value: 'zh-TW', label: i18n('i18n.zh-TW') },
                ],
                // The following properties are optional
                // icon: 'https://example.com/assests/location_icon.png',
                prefill: getLocale() || 'en-US',
            }
            ],
        };
    };
}
