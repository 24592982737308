import React from "react";
import { createEditor } from "../../ui/rete";

import "../../ui/styles.css";
import { ScreenGroup } from "../../nodeSystem/ScreenGroup";

export default ({ patchbay } : {patchbay: ScreenGroup}) => {
  const [showNextTeam, setShowNextTeam] = React.useState(patchbay.getInputData('ShowNextTeam') || false);
  const [show, setShow] = React.useState(patchbay.getInputData('ShowHide') || false);
  React.useEffect(() => {
    (patchbay).setInputData('ShowNextTeam', showNextTeam);
  }, [showNextTeam]);
  React.useEffect(() => {
    (patchbay).setInputData('ShowHide', show);
  }, [show]);

  const [activeScreen, setActiveScreen] = React.useState(patchbay.getCurrentActiveScreen());
  
  return (
    <div>
      <p style={{color: 'white'}}>SHOW/HIDE
      <button onClick={() => {setShow(!show)}}>{show ? 'true' : 'false'}</button></p>
      <div style={{ border: '5px solid green'}}>
        <h1>Starters Screen</h1>
        {(activeScreen && activeScreen.type === 'Starters') ? deactivateButton(patchbay, setActiveScreen) : makeLiveButton('Starters', patchbay, setActiveScreen)}
        <p style={{color: 'white'}}>SHOW NEXT TEAM
        <button onClick={() => {setShowNextTeam(!showNextTeam)}}>{showNextTeam ? 'true' : 'false'}</button></p>
      </div>
      <div style={{ border: '5px solid green'}}>
        <h1>Schedule Screen</h1>
        {(activeScreen && activeScreen.type === 'Schedule') ? deactivateButton(patchbay, setActiveScreen) : makeLiveButton('Schedule', patchbay, setActiveScreen)}
        <p style={{color: 'white'}}>SHOW NEXT TEAM
        <button onClick={() => {setShowNextTeam(!showNextTeam)}}>{showNextTeam ? 'true' : 'false'}</button></p>
      </div>
    </div>
  );
};

const makeLiveButton = (type : string, patchbay : ScreenGroup, setActiveScreen) => {
  return <button onClick={() => {
    const screen = patchbay.data.screens.find(screen => screen.type === type);
    if(screen){
      patchbay.setCurrentActiveScreen(screen.id);
      setActiveScreen(screen);
    }
  }}></button>
};

const deactivateButton = (patchbay : ScreenGroup, setActiveScreen) => {
  return <button onClick={() => {
    patchbay.setCurrentActiveScreen(null);
    setActiveScreen(null);
  }}></button>
};
