import React from 'react';
import Rete from "rete";
import { ScreenGroup } from '../../nodeSystem/ScreenGroup';
import { DataItem } from '../../nodeSystem/defs';

export class NumControl extends Rete.Control {
  static component = ({ value, onChange }) => (
    <input
      type="number"
      value={value}
      ref={ref => {
        ref && ref.addEventListener("pointerdown", e => e.stopPropagation());
      }}
      onChange={e => onChange(+e.target.value)}
    />
  );

  emitter: any;
  key: any;
  component: any;
  props: {
    readonly: boolean;
    value: any;
    onChange: any;
  };

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = NumControl.component;

    const initial = node.data.node.value || 0;

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
      onChange: v => {
        this.setValue(v, node.data.screenGroup, node.data.node.id);
        this.emitter.trigger("process");
      }
    };
  }

  setValue(val, screenGroup: ScreenGroup, nodeId: string) {
    if (!screenGroup.setConstantValue(nodeId, val)) {
      return false;
    }
    this.props.value = val;
    this.putData(this.key, val);
    // @ts-ignore
    this.update();
  }
}


export class StringControl extends Rete.Control {
  static component = ({ value, onChange }) => (
    <input
      type="text"
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  );

  emitter: any;
  key: any;
  component: any;
  props: {
    readonly: boolean;
    value: any;
    onChange: any;
  };

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = StringControl.component;

    const initial = node.data.node.value || '';

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
      onChange: v => {
        this.setValue(v, node.data.screenGroup, node.data.node.id);
        this.emitter.trigger("process");
      }
    };
  }

  setValue(val, screenGroup: ScreenGroup, nodeId: string) {
    console.log('updating val', val);
    if (!screenGroup.setConstantValue(nodeId, val)) {
      return false;
    }
    this.props.value = val;
    this.putData(this.key, val);
    console.log('updating val', val);
    // @ts-ignore
    this.update();
  }
}

export class BooleanControl extends Rete.Control {
  static component = ({ value, onChange }) => (
    <select value={value} onChange={event => onChange(event.target.value)}>
      <option value='false'>false</option>
      <option value='true'>true</option>
    </select>
  );

  emitter: any;
  key: any;
  component: any;
  props: {
    readonly: boolean;
    value: any;
    onChange: any;
  };

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = BooleanControl.component;

    const initial = node.data.node.value || '';

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
      onChange: v => {
        this.setValue(v === 'true' ? true : false, node.data.screenGroup, node.data.node.id);
        this.emitter.trigger("process");
      }
    };
  }

  setValue(val, screenGroup: ScreenGroup, nodeId: string) {
    console.log('updating val', val);
    if (!screenGroup.setConstantValue(nodeId, val)) {
      return false;
    }
    this.props.value = val;
    this.putData(this.key, val);
    console.log('updating val', val);
    // @ts-ignore
    this.update();
  }
}


export class ReadonlyLabelControl extends Rete.Control {
  static generateComponent = (screenGroup : ScreenGroup) => ({ value, onChange }) => (
    <input
      type="text"
      value={`ID: ${screenGroup.data.inputs.find((input : DataItem) => input.id === value).id}`}
      readOnly
    />
  );

  emitter: any;
  key: any;
  component: any;
  props: {
    readonly: boolean;
    value: any;
  };

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = ReadonlyLabelControl.generateComponent(node.data.screenGroup);

    const initial = node.data.node.value || '';

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
    };
  }
}

export class LiveLabelControl extends Rete.Control {
  static generateComponent = (screenGroup : ScreenGroup, screenId: string) => ({ value, onChange }) => (
    <input
      type="text"
      value={`${screenGroup.getCurrentActiveScreenId() === screenId ? 'LIVE' : 'OFF'}`}
      readOnly
    />
  );

  emitter: any;
  key: any;
  component: any;
  props: {
    readonly: boolean;
    value: any;
  };

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = LiveLabelControl.generateComponent(node.data.screenGroup, node.data.screen.id);
    // console.log('REFRESHING');

    const initial = node.data.screen.value || '';

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
    };
  }
}

export class TriggersLabelControl extends Rete.Control {
  static generateComponent = (node : any) => ({ value, onChange }) => (
    <input
      type="text"
      value={`${node.status ? 'ACTIVE' : 'OFF'}`}
      readOnly
    />
  );

  emitter: any;
  key: any;
  component: any;
  props: {
    readonly: boolean;
    value: any;
  };

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = TriggersLabelControl.generateComponent(node.data.screen);

    const initial = node.data.screen.value || '';

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
    };
  }
}

export class TextAreaControl extends Rete.Control {
  static component = ({ value, onChange }) => (
    <textarea style={{
      overflowY: 'scroll',
      height: '100px',
      resize: 'none',
    }}
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  );

  emitter: any;
  key: any;
  component: any;
  props: {
    readonly: boolean;
    value: any;
    onChange: any;
  };

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = TextAreaControl.component;

    const initial = node.data.node.value || '';

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
      onChange: v => {
        this.setValue(v, node.data.screenGroup, node.data.node.id);
        this.emitter.trigger("process");
      }
    };
  }

  setValue(val, screenGroup: ScreenGroup, nodeId: string) {
    console.log('updating val', val);
    if (!screenGroup.setConstantValue(nodeId, val)) {
      return false;
    }
    this.props.value = val;
    this.putData(this.key, val);
    console.log('updating val', val);
    // @ts-ignore
    this.update();
  }
}
