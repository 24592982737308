import React from "react";
import { firebaseRealtimeDbListenForMessagesFromPatchbay, firebaseRealtimeDbListenForMessagesFromPatchbayTriggers, getFirebaseToken } from '../../utils/firebase';
import { getUserID } from "../../auth0";

// import "../../ui/styles.css";
import Starters from "./Starters";
import VmixTriggers from "./VmixTriggers";

export default () => {
  const [triggerPage, setTriggerPage] = React.useState(false);
  const [data, setData] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      const userID = await getUserID();
      if (!userID) {
        try {
          window.alert('You must login!');
        } catch (error) { }
        return;
      }
      let path = window.location.pathname;
      if (!path) {
        return;
      }
      path = path.split('?')[0];
      const splitted = path.split('/');
      let patchbayID = splitted.pop();

      const firebaseToken = await getFirebaseToken();
      if (!firebaseToken) {
        try {
          window.alert('You must login!');
        } catch (error) { }
        return;
      }

      if (patchbayID === 'triggers') {
        patchbayID = splitted.pop();
        setTriggerPage(true);
        firebaseRealtimeDbListenForMessagesFromPatchbayTriggers(firebaseToken, userID, patchbayID, firebaseData => {
          setData(firebaseData);
        });
      } else {
        firebaseRealtimeDbListenForMessagesFromPatchbay(firebaseToken, userID, patchbayID, firebaseData => {
          setData(firebaseData);
        });
      }
    })();
  }, []);

  return (
    <React.Fragment>
      {triggerPage && renderTriggerComponent(data || {})}
      {!triggerPage && data && renderComponent(data.type, data.data)}
    </React.Fragment>
  );
};

const renderTriggerComponent = (triggers: any) => {
  return <VmixTriggers {...triggers} />
}

const renderComponent = (type: string, data: any) => {
  switch (type) {
    case 'Starters':
      return <Starters {...data} />
    default:
      return <div />
  }
};
