import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';
import { TestSchedule } from '../../paramTypes';

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "selector",
        inputs: [{
            id: shortID(),
            name: "inputArray",
            type: "any[]"
        }, {
            id: shortID(),
            name: "arrayIndex",
            type: "number"
        }],
        outputs: [{
            id: shortID(),
            name: "outputElement",
            type: "any"
        }],
    }),
    process: (input: any[], nodeId : string, processedByLiveScreen : boolean) => {
        if (!input || input.length < 2) return [null];
        const arr: any[] = input[0];
        if (!arr || arr.length < input[1]) {
            return [null];
        }
        return [arr[input[1]]];
    },
}
