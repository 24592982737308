import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';
import { InputMatchData, ScheduledMatch, CurrentMatchTeam, WinLoseRecord } from '../../paramTypes';
import { get } from 'lodash';

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "ljlJsonScheduleProcessor",
        inputs: [{
            id: shortID(),
            name: "jsonObject",
            type: "any"
        }, {
            id: shortID(),
            name: "currentRoundIndex",
            type: "number"
        },{
            id: shortID(),
            name: "currentMatchIndex",
            type: "number"
        },{
            id: shortID(),
            name: "inputMatchData",
            type: "InputMatchData[]"
        },{
            id: shortID(),
            name: "currentMatchRecords",
            type: "WinLoseRecord[]"
        },{
            id: shortID(),
            name: "switchSides",
            type: "boolean"
        }],
        outputs: [{
            id: shortID(),
            name: "currentMatchTeams",
            type: "CurrentMatchTeam[]"
        },{
            id: shortID(),
            name: "scheduledMatches",
            type: "ScheduledMatch[]"
        }],
    }),
    process: (input: any[], nodeId: string, processedByLiveScreen : boolean) => {
        if (!input) return [null,null];
        const jsonObject: any = input[0];
        let currentRoundIndex: number = input[1];
        let currentMatchIndex: number = input[2];
        const inputMatchData = input[3] || [];
        const currentMatchRecords : WinLoseRecord[] = input[4] || [];
        const switchSides = input[5] || false;
        if(currentMatchIndex === undefined || currentMatchIndex === null){
            currentMatchIndex = 0;
        }
        if(currentRoundIndex === undefined || currentRoundIndex === null){
            currentRoundIndex = 0;
        }

        if (!jsonObject || !jsonObject.schedule) {
            return [null,null];
        }
        if (!jsonObject.schedule.rounds) {
            return [null,null];
        }
        const getTeamData = (tricode: string) => {
            if (!tricode) return null;
            return get(jsonObject, `teams.${tricode}`);
        }

        const rounds = jsonObject.schedule.rounds;
        const currentRound = rounds[currentRoundIndex];
        if(!currentRound){
            return [null,null];
        }
        const currentMatchTeams: CurrentMatchTeam[] = [];
        const scheduledMatches : ScheduledMatch[] = currentRound.matches.map((match, index) => {
            const team100 = getTeamData(match.team100);
            const team200 = getTeamData(match.team200);
            const inputMatch: InputMatchData = inputMatchData[index];
            if (!inputMatch) {
                console.log('missing record for match at index ', index);
                return null;
            }

            const team100Tricode = match.team100;
            const team200Tricode = match.team200;
            const team100LogoUrl = get(team100, 'images.logoFullColor');
            const team200LogoUrl = get(team200, 'images.logoFullColor');

            if(index === currentMatchIndex){
                const team100MatchTeamsData = {
                    LogoUrl: team100LogoUrl,
                    LossRecord: (currentMatchRecords && currentMatchRecords[0] && currentMatchRecords[0].Losses) || 0,
                    WinRecord:  (currentMatchRecords && currentMatchRecords[0] && currentMatchRecords[0].Wins) || 0,
                    Tricode: match.team100,
                };
                const team200MatchTeamsData = {
                    LogoUrl: team200LogoUrl,
                    LossRecord: (currentMatchRecords && currentMatchRecords[1] && currentMatchRecords[1].Losses) || 0,
                    WinRecord:  (currentMatchRecords && currentMatchRecords[1] && currentMatchRecords[1].Wins) || 0,
                    Tricode: match.team200,
                };
                if(switchSides){
                    currentMatchTeams.push(team200MatchTeamsData);
                    currentMatchTeams.push(team100MatchTeamsData);
                }else{
                    currentMatchTeams.push(team100MatchTeamsData);
                    currentMatchTeams.push(team200MatchTeamsData);
                }
            }

            const teams : ScheduledMatch['Teams'] = [];
            const team100Team : ScheduledMatch['Teams'][0] = {
                TeamTricode: team100Tricode,
                IsWinner: inputMatch.WinningTeamIndex === 0,
                LogoUrl: team100LogoUrl,
                Score: inputMatch.TeamScores[0],
                TeamName: team100.name,
            };
            const team200Team : ScheduledMatch['Teams'][0] = {
                TeamTricode: team200Tricode,
                IsWinner: inputMatch.WinningTeamIndex === 1,
                LogoUrl: team200LogoUrl,
                Score: inputMatch.TeamScores[1],
                TeamName: team200.name,
            };
            if(switchSides){
                teams.push(team200Team);
                teams.push(team100Team);
            }else{
                teams.push(team100Team);
                teams.push(team200Team);
            }

            return new ScheduledMatch({
                IsFinished: inputMatch.IsFinished,
                MatchTime: match.datetime,
                Games: inputMatch.Games,
                Teams: teams,
            });
        });




        return [currentMatchTeams, scheduledMatches];
    },
}
