import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';
import { StandingsEntry, StandingsInput } from '../../paramTypes';

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "ljlStandings",
        inputs: [{
            id: shortID(),
            name: "jsonObject",
            type: "any"
        }, {
            id: shortID(),
            name: "orderedStandings",
            type: "StandingsInput[]"
        }],
        outputs: [{
            id: shortID(),
            name: "orderedStandingsOutput",
            type: "StandingsEntry[]"
        }],
    }),
    process: (input: any[], nodeId: string, processedByLiveScreen : boolean) => {
        if (!input) return [null];
        if (input.length < 2) {
            return [null];
        }

        const json = input[0];
        const output: StandingsEntry[] = [];
        input[1].forEach((input: StandingsInput) => {
            const entry = new StandingsEntry(input);
            if (json) {
                const { LogoUrl, TeamName } = getLogoUrlAndTeamName(json, input.Tricode);
                entry.LogoUrl = LogoUrl;
                entry.TeamName = TeamName;
            }
            output.push(entry);
        });

        return [output];
    },
}

const getLogoUrlAndTeamName = (json: any, tricode: string) => {
    const teamData = json && json.teams && json.teams[tricode.toUpperCase()];
    if (teamData) {
        return {
            TeamName: teamData.name,
            LogoUrl: teamData.images && teamData.images.logoSplash,
        }
    }
    return {};
};
