/*
 Short-ID-Generator
 Originally from https://github.com/Sommerfeld/short-id-gen
 */

const valueToChar = (value : any) => {
    if (value > 9) {
        let asciiDec = 55 + value;
        if (asciiDec > 90) asciiDec += 6;
        return String.fromCharCode(asciiDec);
    }
    return value.toString();
};

const valueToString = (value : any) : any => {
    const x = value % 62;
    const y = Math.floor(value / 62);
    return y > 0 ? valueToString(y) + valueToChar(x) : valueToChar(x);
};

export default (minLength : number = 10, maxLength : number = 10) => {

    // Validation Check
    if (minLength <= 0 || minLength > maxLength) {
        console.log(new Error('Invalid Values. (short-id-gen)'));
        return -1;
    }

    // Default Length (0 params)
    if (typeof minLength === 'undefined') minLength = maxLength = 10;

    // if exact length (1 param)
    if (typeof maxLength === 'undefined') maxLength = minLength;

    const minRandom = minLength === 1 ? 0 : Math.pow(62, minLength - 1);
    const maxRandom = Math.pow(62, maxLength) - 1;
    const random = Math.floor(Math.random() * (maxRandom - minRandom) + minRandom);

    return valueToString(random);
};
