import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { getHashParam } from '../../utils/uri';
import storage from '../../utils/storage';
import { getUserInfo, isLoggedIn, getUserID } from '../../auth0';
import { Link } from 'react-browser-router';

import {
  GlobalContainer,
  Menu,
  MenuItems,
  // Logo,
} from './styles';
import VmixTriggers from '../../components/Viewer/VmixTriggers';
import Viewer from '../../components/Viewer';
import Patchwork from '../../components/Patchwork';
import Home from '../../components/Home';
import Controller from '../../components/Controller';
import { ScreenGroup } from '../../nodeSystem/ScreenGroup';
// import { operationsAdmins } from '../../utils/constants';

const operationsAdmins = ['5a15376bf391697c5b5c4d71'];

const updateUserInfo = async (setUserInfo) => {
  const userInfo = await getUserInfo();
  setUserInfo(userInfo);
}


const patchbayReducer = (state, jsonData) => {
  return new ScreenGroup(jsonData);
}

export default () => {
  const isViewer = window.location.href.indexOf('/viewer/') >= 0 || window.location.href.indexOf('/vmix') >= 0;
  const [patchbay, dispatchPatchbay] = React.useReducer(patchbayReducer, isViewer ? null : new ScreenGroup('{}'));
  const [userInfo, setUserInfo] = React.useState(null);
  const [isOperationsAdmin, setIsOperationsAdmin] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      // because of the s3 routing setup, we need the auth callback here on the homepage.
      const idToken = getHashParam('id_token');
      if (idToken) {
        const accessToken = getHashParam('access_token');
        storage.set('broadcast-access-token', accessToken);
        storage.set('broadcast-id-token', idToken);
        window.location.href = decodeURIComponent(window.atob(getHashParam('state')));
      }
      updateUserInfo(setUserInfo);

      const loggedIn = await isLoggedIn();
      if (loggedIn) {
        const userID = await getUserID();
        if (operationsAdmins.includes(userID)) {
          setIsOperationsAdmin(true);
        } else {
          setIsOperationsAdmin(false);
        }
      } else {
        setIsOperationsAdmin(false);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <GlobalContainer>
        {
          !isViewer &&
          <Menu>
            <MenuItems>
              {/* <Logo src="http://192.168.1.169:8080/images/dekki-logo-orange.svg" alt="DEKKI" /> */}
              <Link to="/">Home</Link>
              {isOperationsAdmin && <Link to="/controller">Controller</Link>}
              {isOperationsAdmin && <Link to="/patchwork">Patchwork</Link>}

            </MenuItems>
            {
              userInfo &&
              <MenuItems>

                <span>Logged in as
                <img src={`https://cdn.dekki.com/uploads/users/${userInfo && userInfo.id}/avatar/27`} alt='' />
                  <strong>{userInfo && userInfo.displayName}</strong></span>
              </MenuItems>
            }
          </Menu>
        }


        <main>
          {!isViewer && <Route path={`(/.*/|/)`} component={Home} />}
          {isOperationsAdmin && <Route path={`(/.*/|/)controller`} render={() => <Controller patchbay={patchbay}/>} />}
          {isOperationsAdmin && <Route path={`(/.*/|/)patchwork`} render={() => <Patchwork patchbay={patchbay} dispatchPatchbay={dispatchPatchbay}/>} />}
          {isOperationsAdmin && <Route path={`(/.*/|/)viewer/(.*)`} render={() => <Viewer />} />}
        </main>
      </GlobalContainer>
    </React.Fragment>
  );
}
