import https from 'https';
import http from 'http';

export const doRestCall = (requestParams: any, body: any = undefined, doHttp = false, timeoutMs = 0) => new Promise((resolve) => {
    let rawData = '';
    try{
        let timedOut = true;
        let data = {};
        if (body) body = JSON.stringify(body);

        const callback = res => {
            timedOut = false;
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res.setEncoding('utf8');
                res.on('data', chunk => {
                    rawData += chunk;
                });

                res.on('end', () => {
                    if (rawData) {
                        data = JSON.parse(rawData);
                    } else {
                        data = {};
                    }
                    resolve(data);
                });
            } else {
                res.setEncoding('utf8');
                res.on('data', chunk => {
                    rawData += chunk;
                });

                res.on('end', () => {
                    console.log(`error calling api : ${requestParams.path}. Received status code: ${res.statusCode}`, rawData);
                    resolve({
                        errorRawData: rawData,
                    });
                });
            }
        };

        let req;
        if (doHttp) {
            req = http.request(requestParams, callback);
        } else {
            req = https.request(requestParams, callback);
        }

        req.setHeader('Origin', window.location.host);

        if (timeoutMs > 0) {
            // None of the built-in timeout functions/callbacks are working for the 'req' so making my own - stevenm 2020-02-03
            setTimeout(() => {
                if (timedOut) {
                    req.abort();
                    console.log(`error calling api : ${requestParams.path}. Timed out.`);
                    resolve({
                        errorRawData: 'Request Timed Out',
                    });
                }
            }, timeoutMs);
        }
        if (body) req.end(body);
        else req.end();
    } catch (error) {
        console.log(`error calling api : ${requestParams.path}. Received error: ${error}`);
        resolve({
            errorRawData: error,
        });
    }
});
