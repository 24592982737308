import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "logicalOr",
        inputs: [{
            id: shortID(),
            name: "input1 (priority)",
            type: "any"
        }, {
            id: shortID(),
            name: "input2",
            type: "any"
        }],
        outputs: [{
            id: shortID(),
            name: "output",
            type: "any"
        }],
    }),
    process: (input: any[], nodeId : string, processedByLiveScreen : boolean) => {
        if(!input) return [null];
        if(input.length === 1){
            return [input[0]];
        }
        if(input[0] === null || input[0] === undefined){
            return [input[1]];
        }
        return [input[0]];
    },
}
