import '@firebase/auth';
import '@firebase/database';
import { firebase } from '@firebase/app';
import GraphQL from './graphql';
import shortID from './shortID';
import storage from './storage';
import _ from 'lodash';

const Memory = {
    firebaseToken: null,
};
export const config = {
    apiKey: 'AIzaSyAGo1M9n3uT8b9ZAZnDF9pmUsZYxuAwOAs',
    authDomain: 'spiritual-hour-122502.firebaseapp.com',
    databaseURL: 'https://spiritual-hour-122502.firebaseio.com',
    projectId: 'spiritual-hour-122502',
    storageBucket: 'spiritual-hour-122502.appspot.com',
    messagingSenderId: '230738871311',
};
let currentFirebaseTokenPromise : Promise<string> = null, firebaseInitialized = false;
const TIMEOUT = 60000 * 55;

const firebaseViewTable = process.env.REACT_APP_FIREBASE_VIEW_TABLE || 'frame';

const getFirebaseTokenFromApi = () => GraphQL(`{
            firebase
        }`).then(data => data && data.data && data.data.firebase);

export const getFirebaseToken = async () => {
    if (!currentFirebaseTokenPromise) {
        currentFirebaseTokenPromise = new Promise(resolve => {
            let currentData = Memory.firebaseToken;
            if (currentData) {
                return resolve(currentData);
            }
            currentData = getFirebaseTokenFromCacheOnly();
            if (currentData) {
                Memory.firebaseToken = currentData;
                return resolve(currentData);
            }
            getFirebaseTokenFromApi().then(data => {
                if (data) {
                    Memory.firebaseToken = data;
                    storage.set('firebaseToken', data);
                    storage.set('last-firebaseTokenTime', new Date().getTime());
                }
                return resolve(data);
            }).catch(() => resolve(null));
        });
    }
    return currentFirebaseTokenPromise;
};

export const getFirebaseTokenFromCacheOnly = () => {
    const stored = storage.get('firebaseToken');
    if (!stored) {
        return undefined;
    }
    const lastTime = Number(storage.get('last-firebaseTokenTime'));
    if (isNaN(lastTime) || Date.now() - lastTime > TIMEOUT) {
        return undefined;
    }
    return stored;
};

let firebaseInitializedPromise = null;
export const initializeFirebase = async token => {
    if (!firebaseInitialized) {
        if (!firebaseInitializedPromise) {
            firebaseInitializedPromise = new Promise(async resolve => {
                await firebase.initializeApp(config);
                await firebase.auth().signInWithCustomToken(token);
                firebaseInitialized = true;
                resolve();
            });
        }
        await firebaseInitializedPromise;
    }
}

const dbRefs = {};

export const firebaseSendHeartbeatToStream = async (token, hostUserID, streamID, viewID) =>
    await firebaseRealtimeDbSetPath(token, `${process.env.REACT_APP_ENVIRONMENT || 'local'}/streams/${hostUserID}/${streamID}/activeViews/${viewID}`, new Date().toISOString());

export const firebaseRealtimeDbSetPath = async (token, path, value) => {
    try {
        if (!firebaseInitialized) {
            if (!token) {
                return;
            }
            await initializeFirebase(token);
        }
        const db = firebase.database();
        const ref = db.ref(path);
        await ref.set(value);
    } catch (error) {
        console.log('error ', error);
    }
}

export const firebaseRealtimeDbListenToCgpcScreen = async (token, screenIndex, callback) => {
    if (!firebaseInitialized) {
        if (!token) {
            callback([]);
            return;
        }
        await initializeFirebase(token);
    }
    const db = firebase.database();

    const ref = db.ref(`${process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'prod' : 'local'}/ljl/cgpc/${screenIndex}`);
    ref.on('value', snapshot => {
        // console.log('snapshot! ', snapshot.val());
        callback(snapshot.val());
    });
};

export const firebaseRealtimeDbStopListeningToCgpcScreen = async (screenIndex) => {
    if (!firebaseInitialized) return;
    const db = firebase.database();
    const ref = db.ref(`${process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'prod' : 'local'}/ljl/cgpc/${screenIndex}`);
    ref.off();
};

export const firebaseRealtimeDbUpdatePatchbayOutput = async (token, userID, patchbayID, updatedState) => {
    try {
        if (!firebaseInitialized) {
            if (!token) {
                return;
            }
            await initializeFirebase(token);
        }
        const refID = `${userID}:${patchbayID}`;
        if (!dbRefs[refID]) {
            const db = firebase.database();
            dbRefs[refID] = db.ref(`${process.env.REACT_APP_ENVIRONMENT || 'local'}/${firebaseViewTable}/${userID}/${patchbayID}`);
        }

        const newState = _.cloneDeepWith(updatedState, value => {
            if (value === undefined) {
                return null;
            }
        });

        // const newState = _(updatedState).omitBy(_.isUndefined).value();
        // newState.meta = _(newState.meta).omitBy(_.isUndefined).value();
        await dbRefs[refID].set(newState);
        return patchbayID;
    } catch (error) {
        console.log('error ', error);
        return null;
    }
};

export const firebaseRealtimeDbListenForMessagesFromPatchbay = async (token, userID, patchbayID, callback) => {
    if (!patchbayID) {
        console.log('missing patchbayID');
        return;
    }
    if (!firebaseInitialized) {
        if (!token) {
            callback([]);
            return;
        }
        await initializeFirebase(token);
    }
    const db = firebase.database();

    const ref = db.ref(`${process.env.REACT_APP_ENVIRONMENT || 'local'}/${firebaseViewTable}/${userID}/${patchbayID}`);
    ref.on('value', snapshot => {
        // console.log('snapshot! ', snapshot.val());
        callback(snapshot.val());
    });
};

export const firebaseRealtimeDbListenForMessagesFromPatchbayTriggers = async (token, userID, patchbayID, callback) => {
    if (!patchbayID) {
        console.log('missing patchbayID');
        return;
    }
    if (!firebaseInitialized) {
        if (!token) {
            callback([]);
            return;
        }
        await initializeFirebase(token);
    }
    const db = firebase.database();

    const ref = db.ref(`${process.env.REACT_APP_ENVIRONMENT || 'local'}/${firebaseViewTable}/${userID}/${patchbayID}/triggers`);
    ref.on('value', snapshot => {
        // console.log('snapshot! ', snapshot.val());
        callback(snapshot.val());
    });
};

