import shortID from '../../../utils/shortID';
import { ProcessingNode } from '../../defs';
import { ChampionMappings, createLjlPlayerInfo, LjlPlayerInfos } from '../../paramTypes';

export default {
    create: () => new ProcessingNode({
        id: shortID(),
        type: "lolChampionMapper",
        inputs: [{
            id: shortID(),
            name: "topChampionImage",
            type: "string"
        }, {
            id: shortID(),
            name: "jungleChampionImage",
            type: "string"
        }, {
            id: shortID(),
            name: "midChampionImage",
            type: "string"
        }, {
            id: shortID(),
            name: "adcChampionImage",
            type: "string"
        }, {
            id: shortID(),
            name: "supportChampionImage",
            type: "string"
        }],
        outputs: [{
            id: shortID(),
            name: "output",
            type: "ChampionMappings"
        }],
    }),
    process: (input: any[], nodeId: string, processedByLiveScreen : boolean) => {
        if (!input) return [new ChampionMappings({})];

        return [new ChampionMappings({
            Top: { ChampionUrl: input[0] || '' },
            Jungle: { ChampionUrl: input[1] || '' },
            Mid: { ChampionUrl: input[2] || '' },
            Adc: { ChampionUrl: input[3] || '' },
            Support: { ChampionUrl: input[4] || '' },
        })];
    },
}
